/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box } from '@mui/system';
import RoadOnIcon from '@mui/icons-material/EditRoadOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import StarIcon from '@mui/icons-material/Star';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Modal from '@mui/material/Modal';
import { Button, LinearProgress } from '@mui/material';
import Alert from './Components/Alert';
import SECRETS from './secrets';
import COLORS from './Components/styles/Colors';
import TopBar from './Components/TopBar';
import DropdownFilter from './Components/DropdownFilter';
import DatePicker from './Components/DatePicker';
import DropdownMenu from './Components/DropdownMenu';
import DarkInput from './Components/basics/DarkInput';
import DarkButton from './Components/basics/DarkButton';
import DarkSelect from './Components/basics/DarkSelect';
import DarkIconButton from './Components/basics/DarkIconButton';
import Divider from './Components/basics/Divider';

const styles = {
  dataGrid: {
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
      borderRadius: '0',
      backgroundColor: COLORS.NEUTRAL700,
      color: 'white',
      '& *': {
        color: 'white !important',
      },
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: COLORS.NEUTRAL800,
      '& *': {
        color: 'white',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiCircularProgress-root': {
      color: COLORS.GREEN400,
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  },

};
const buildRequestOptions = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
  baseURL: SECRETS.SERVERURL,
});

const getRoutes = async (token, setIsLogged) => {
  let loginError = false;
  const response = await axios.get('/controlruta', buildRequestOptions(token)).catch((error) => {
    if (error.response.status === 401) loginError = true;
  });
  if (!loginError) return response.data;
  setIsLogged(false);
  return false;
};

const getPointByRoute = async (token, routeId) => {
  const response = await axios.get(`/controlpunto/byRuta/${routeId}`, buildRequestOptions(token));
  return response.data;
};

const getModels = async (token, setIsLogged) => {
  const rawRoutes = await getRoutes(token, setIsLogged);

  const routesCompose = rawRoutes.map(async (route) => {
    const rawRoutePoints = await getPointByRoute(token, route.id);

    const points = rawRoutePoints.map((point) => ({
      id: String(point.id),
      time: point.tiempo,
      label: `${route.nombre} → ${point.nombre}`,
    }));

    return ({ id: route.id, label: route.nombre, points });
  });

  const routes = await Promise.all(routesCompose);

  return routes;
};

const getRouteLog = async (token, date) => {
  const fecha = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  const response = await axios.post('/controlrecorrido', { fecha }, buildRequestOptions(token));
  return response.data;
};

const getUnits = async (token) => {
  const response = await axios.get('/unidades/organizacion', buildRequestOptions(token));
  return response.data;
};

const getUsers = async (token) => {
  const response = await axios.get('/usuarios/organizacion', buildRequestOptions(token));
  return response.data;
};

const createFilter = async (token, name, filters) => {
  const response = await axios.post('/controltiempofavorito', { name, filters }, buildRequestOptions(token));
  return response.data;
};

const getFilters = async (token) => {
  const data = await axios.get('/controltiempofavorito', buildRequestOptions(token));

  return data.data;
};

const deleteFilter = async (token, id) => {
  await axios.delete(`/controltiempofavorito/${id}`, buildRequestOptions(token));
};

const updateFilter = async (token, id, data) => {
  await axios.patch(`/controltiempofavorito/${id}`, data, buildRequestOptions(token));
};

const indexUsers = (users) => users.reduce((acc, user) => ({ ...acc, [user.idUsuario]: user }), {});
const indexUnits = (units) => units.reduce((acc, unit) => ({ ...acc, [unit.idUnidad]: unit }), {});
const indexPointsByModel = (models) => models.reduce((acc, model) => {
  const indexedPoints = model.points.reduce((acc2, point) => ({ ...acc2, [point.id]: point }), {});
  return ({ ...acc, ...indexedPoints });
}, {});

function getDiff({ arrival, start, time }) {
  const startTime = new Date(start).getTime();
  const arrivalTime = new Date(arrival).getTime();

  const diff = (arrivalTime - startTime) / (1000 * 60);

  const delay = Math.floor((diff - time));
  return { delay, diff: Math.floor(diff) };
}
function convertToShortLocalTime(isoString) {
  const date = new Date(isoString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}
function formatDateWithoutYear(isoString) {
  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, '0');
  const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  const monthName = monthNames[date.getMonth()];
  return `${day}-${monthName}`;
}

const getData = async (token, date, model) => {
  if (date === undefined) return undefined;
  if (model === undefined) return undefined;

  const routeLog = await getRouteLog(token, date);
  const units = await getUnits(token);
  const users = await getUsers(token);

  const indexedUsers = indexUsers(users);
  const indexedUnits = indexUnits(units);
  const indexedModelPoints = indexPointsByModel(model);

  const collection = routeLog.map((log) => {
    const {
      nombreRuta,
      idUnidad,
      idUsuario,
      nombreOperador,
      inicio,
      fin,
      terminado,
      puntos,
      idControlRuta,
    } = log;

    if (!terminado) return null;

    const unit = indexedUnits[idUnidad].unidad;
    const checker = indexedUsers[idUsuario].nombre;

    const base = {
      route: nombreRuta,
      checker,
      operator: nombreOperador,
      unit,
      start: convertToShortLocalTime(inicio),
      end: convertToShortLocalTime(fin),
      date: formatDateWithoutYear(inicio),
    };

    const breakdown = puntos.map((point) => {
      const pointModel = indexedModelPoints[point.idControlPunto];
      if (pointModel === undefined) return null;
      const { time } = pointModel;
      const { delay, diff } = getDiff({
        arrival: point.fecha,
        start: inicio,
        time,
      });

      return ({
        ...base,
        id: point.id,
        point: point.nombrePunto,
        arrival: convertToShortLocalTime(point.fecha),
        time,
        delay,
        diff,
        descriptor: {
          routeId: idControlRuta,
          pointId: point.idControlPunto,
          delayed: delay > 0,
        },
      });
    });

    return breakdown;
  }).flat();

  return collection.filter((item) => item !== null);
};

const ConfirmationModal = ({
  confirm, cancel, description, title, open,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onConfirm = async () => {
    setError(false);
    setLoading(true);

    try {
      await confirm.onClick();
    } catch (e) {
      setError(true);
    }

    setLoading(false);
  };

  return (
    <Modal open={open} onClose={cancel.onClick}>
      <Box
        component="div"
        sx={styles.modal}
      >
        <h3>{title}</h3>
        <p>{description}</p>
        <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
          <Button onClick={onConfirm} variant="outlined" disabled={loading}>{confirm.label}</Button>
          <Button onClick={cancel.onClick} variant="contained" disabled={loading}>{cancel.label}</Button>
        </Box>
        <br />
        {loading && <LinearProgress color="info" />}
        {error && <p>Hubo un error de conexión, inténtalo de nuevo más tarde.</p>}
      </Box>
    </Modal>
  );
};

const SavedFilters = (props) => {
  const [saved, setSaved] = useState([]);
  const [current, setCurrent] = useState(undefined);

  const [filterName, setFilterName] = useState('');

  const [modal, setModal] = useState('');

  const downloadFilters = async () => {
    const data = await getFilters(props.token);
    setSaved(data);
    // props.onAlert({ message: 'Filtros cargados', type: 'success' });
  };

  const onChangeFilter = ({ target }) => {
    const filterSaved = saved.find(({ id }) => id === target.value);
    setCurrent(filterSaved);

    if (filterSaved === undefined) return;
    props.setState(filterSaved.filters);
  };

  const onCreateFilter = async () => {
    createFilter(props.token, filterName, props.state).then((data) => {
      setSaved([...saved, data]);
      downloadFilters();
      setFilterName('');
      setCurrent(undefined);
      props.onAlert({ message: 'Filtro guardado', type: 'success' });
    });
  };

  const onDeleteFilter = async () => {
    if (current?.id === undefined) return;

    await deleteFilter(props.token, current.id).then(() => {
      downloadFilters();
      setCurrent(undefined);
      props.onAlert({ message: 'Filtro eliminado', type: 'success' });
    }).catch((err) => {
      props.onAlert({ message: 'Error al eliminar filtro', type: 'error' });
      console.error(err);
    });
  };

  const onUpdateFilter = async () => {
    if (current?.id === undefined) return;

    await updateFilter(props.token, current.id, {
      filters: props.state,
    }).then(() => {
      props.onAlert({ message: 'Filtro actualizado', type: 'success' });
      downloadFilters();
    }).catch((err) => {
      props.onAlert({ message: 'Error al actualizar filtro', type: 'error' });
      console.error(err);
    });
  };

  useEffect(() => {
    downloadFilters();
  }, []);

  return (
    <DropdownMenu
      iconOpen={<StarIcon />}
      disabled={props.disable}
    >
      <Box
        component="div"
      >
        <Box
          component="div"
          sx={{
            display: 'flex', padding: '1rem', flexDirection: 'column', gap: '0.5rem',
          }}
        >
          <h3>Guardar filtros</h3>
          <DarkInput
            id="operator"
            type="text"
            label="Nombre del nuevo filtro"
            variant="outlined"
            value={filterName}
            error={undefined}
            onChange={({ target }) => setFilterName(target.value)}
            icon={<AlarmOnIcon />}
          />
          <DarkButton type="button" variant="outlined" sx={styles.buttonNormal} onClick={onCreateFilter}>
            Agregar
          </DarkButton>
        </Box>

      </Box>
      <Divider />
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          gap: '0.5rem',
        }}
      >
        <h3>Filtros guardados</h3>
        <DarkSelect
          value={current?.id ?? ''}
          onChange={onChangeFilter}
          options={saved.map(({ id, name }) => ({ label: name, value: id }))}
          placeholder={
              saved === undefined ? 'Cargando filtros...' : (saved.length > 0 ? 'Selecciona un filtro' : 'No hay filtros guardados')
            }
        />
        <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DarkIconButton
            icon={<DeleteIcon />}
            onClick={() => setModal('delete')}
            disabled={current === undefined}
          />
          <DarkIconButton
            icon={<SaveIcon />}
            onClick={() => setModal('update')}
            disabled={current === undefined}
          />
        </Box>
      </Box>

      <ConfirmationModal
        title="Eliminar filtro"
        description="¿Estás seguro de que quieres eliminar este filtro?"
        open={modal === 'delete'}
        confirm={{
          onClick: async () => {
            await onDeleteFilter();
            setModal('');
          },
          label: 'Eliminar',
        }}
        cancel={{
          onClick: () => setModal(''),
          label: 'Cancelar',
        }}
      />
      <ConfirmationModal
        title="Actualizar filtro"
        description="¿Estás seguro de que quieres actualizar este filtro?"
        open={modal === 'update'}
        confirm={{
          onClick: async () => {
            await onUpdateFilter();
            setModal('');
          },
          label: 'Actualizar',
        }}
        cancel={{
          onClick: () => setModal(''),
          label: 'Cancelar',
        }}
      />
    </DropdownMenu>
  );
};

const Filters = ({
  models,
  data,
  setLeakedData,
  token,
  onAlert,
}) => {
  const filterRef = useRef({});
  const [block, setBlock] = useState(true);

  const filterData = (useFilter) => {
    const f = useFilter ?? filterRef.current ?? {};

    if (data === undefined) return;

    const leakedData = data.filter((row) => {
      const { routeId, pointId, delayed } = row.descriptor;

      if (f[`r${routeId}`] !== true) return false;
      if (f[`p${pointId}`] !== true) return false;
      if (f.delay === 'delay' && !delayed) return false;

      return true;
    });

    onAlert({ message: 'Filtrado', type: 'info' });
    setLeakedData(leakedData);
  };

  const handleChangeFilter = (newFilter) => {
    filterData(newFilter);

    // eliminar los puntos que no están en las rutas a filtrar
    // para solo guardar en db los puntos necesarios
    if (models === undefined) return;

    const nf = models.reduce((acc, model) => {
      if (acc[`r${model.id}`] === true) return acc;

      model.points.forEach((point) => {
        if (newFilter[`p${point.id}`] === true) acc[`p${point.id}`] = false;
      });

      acc.rall = false;
      acc.pall = false;

      return acc;
    }, newFilter);

    filterRef.current = nf;
  };

  useEffect(() => {
    filterData();
    if (data === undefined) { setBlock(true); } else { setBlock(false); }
  }, [data]);

  const radio = {
    name: 'delay',
    defaultValue: 'all',
    options: [
      { id: 1, value: 'all', label: 'Todos' },
      { id: 2, value: 'delay', label: 'Con Atraso' },
    ],
  };

  const leakedModelPoints = () => {
    if (!models) return undefined;
    const points = models.reduce((acc, model) => (filterRef.current[`r${model.id}`] === true ? [...acc, ...model.points] : acc), []);

    return (points.length > 0) ? points : undefined;
  };

  const points = leakedModelPoints();

  return (
    <Box sx={{
      display: 'inline-flex',
      padding: '1px',
      borderRadius: '0.3rem',
      backgroundColor: COLORS.GREEN400,
      '& *': {
        color: COLORS.SLATE300,
      },
      '& > button:first-of-type': {
        borderRadius: '0.3rem 0 0 0.3rem',
      },
      '& > button:last-child': {
        borderRadius: '0 0.3rem 0.3rem 0',
      },
    }}
    >
      <DropdownFilter
        state={filterRef.current}
        setState={handleChangeFilter}
        iconOpen={<RoadOnIcon />}
        checkbox={block ? undefined : { name: 'r', options: models }}
      />
      <DropdownFilter
        state={filterRef.current}
        setState={handleChangeFilter}
        iconOpen={<PlaceIcon />}
        checkbox={block || points === undefined ? undefined : { name: 'p', options: points }}
      />
      <DropdownFilter
        state={filterRef.current}
        setState={handleChangeFilter}
        iconOpen={<AlarmOnIcon />}
        radio={block ? undefined : radio}
      />
      <SavedFilters
        token={token}
        state={filterRef.current}
        setState={handleChangeFilter}
        onAlert={onAlert}
        disable={block}
      />
    </Box>
  );
};

const thStyle = {
  textAlign: 'left',
  padding: '5px',
  backgroundColor: 'rgb(51, 65, 85)',
  color: 'rgb(248, 250, 252)',
  minWidth: '64px',
};

const tdStyle = {
  textAlign: 'left',
  padding: '2px',
  borderBottom: '1px solid rgba(224, 224, 244, 1)',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '0.875rem',
  lineHeight: '1.43',
  letterSpacing: '0.01071em',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
};

const startCellStyle = {
  paddingLeft: '3em',
};

const endCellStyle = {
  paddingRight: '3em',
};

const TablePoints = ({ data }) => {
  if (data === undefined) return <h1 style={{ textAlign: 'center' }}>Buscando...</h1>;
  if (data.length === 0) return <h1 style={{ textAlign: 'center' }}>No hay datos para mostrar en esta fecha</h1>;

  return (
    <table style={{ borderSpacing: 0, width: '100%' }}>
      <thead>
        <tr>
          <th style={{ ...thStyle, ...startCellStyle, minWidth: '190px' }}>Ruta</th>
          <th style={{ ...thStyle, minWidth: '200px' }}>Checador</th>
          <th style={{ ...thStyle, minWidth: '200px' }}>Operador</th>
          <th style={{ ...thStyle }}>Fecha</th>
          <th style={{ ...thStyle }}>Inicio</th>
          <th style={{ ...thStyle }}>Fin</th>
          <th style={{ ...thStyle }}>Unidad</th>
          <th style={{ ...thStyle, minWidth: '120px' }}>Punto</th>
          <th style={{ ...thStyle }}>Llegada</th>
          <th style={{ ...thStyle }}>Estimado</th>
          <th style={{ ...thStyle }}>Tiempo</th>
          <th style={{ ...thStyle, ...endCellStyle }}>Retraso</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td style={{ ...tdStyle, ...startCellStyle }}>{item.route}</td>
            <td style={tdStyle}>{item.checker}</td>
            <td style={tdStyle}>{item.operator}</td>
            <td style={tdStyle}>{item.date}</td>
            <td style={tdStyle}>{item.start}</td>
            <td style={tdStyle}>{item.end}</td>
            <td style={tdStyle}>{item.unit}</td>
            <td style={tdStyle}>{item.point}</td>
            <td style={tdStyle}>{item.arrival}</td>
            <td style={tdStyle}>{item.time}</td>
            <td style={tdStyle}>
              {item.delay}
              {' '}
              min
            </td>
            <td style={{ ...tdStyle, ...endCellStyle, color: item.diff > 0 ? 'rgb(220, 38, 38)' : 'rgb(2, 132, 199)' }}>
              {item.diff}
              {' '}
              min
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const CheckedScreen = ({
  token,
  logOut,
  checkSession,
  secondary,
}) => {
  const [isLogged, setIsLogged] = useState(true);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [alertType, setAlertType] = useState('');

  const [model, setModel] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [date, setDate] = useState(new Date());

  const [leakedData, setLeakedData] = useState(undefined);

  const navigate = useNavigate();

  const createAlert = ({ message, type }) => {
    setMsg(message);
    setAlertType(type);
    setOpen(true);
  };

  const createErrorAlert = (err) => {
    console.error(err);
    createAlert({ type: 'error', message: 'Algo salió mal' });
  };

  useEffect(() => { if (!checkSession()) setIsLogged(false); });

  useEffect(() => {
    if (secondary === 'false') return;
    navigate('/');
  }, [secondary]);

  useEffect(() => {
    if (!isLogged) {
      logOut();
      navigate('/');
    }
  }, [isLogged]);

  useEffect(() => {
    const initFetch = async () => {
      const MODELS = await getModels(token, setIsLogged);
      setModel(MODELS);

      const DATA = await getData(token, date, MODELS);
      setData(DATA);

      return { models: MODELS, data: DATA };
    };

    initFetch().catch(createErrorAlert);
  }, []);

  useEffect(() => {
    const initFetch = async () => {
      const DATA = await getData(token, date, model);
      setData(DATA);
    };

    setLeakedData(undefined);

    initFetch().catch(createErrorAlert);
  }, [date]);

  const Tools = (
    <>
      <DatePicker label="Fecha de consulta" value={date} onChange={setDate} />
      <Filters
        models={model}
        data={data}
        setLeakedData={setLeakedData}
        token={token}
        onAlert={createAlert}
      />
    </>
  );

  return (
    <>
      <Alert
        {...{
          msg,
          alertType,
          open,
          setOpen,
        }}
      />
      <TopBar
        secondary={secondary}
        tools={Tools}
        handleLogOut={logOut}
        location="Tiempos"
      >
        <TablePoints data={leakedData} />
      </TopBar>
    </>
  );
};

export default CheckedScreen;
