import React, { useEffect, useState } from 'react';
import { IconButton, Menu } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import COLORS from './styles/Colors';

const DropdownMenu = ({
  iconOpen, disabled, children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onClickMenuButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (disabled) closeMenu();
  }, [disabled]);

  const Popup = (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: 350,
          backgroundColor: COLORS.NEUTRAL800,
          color: COLORS.SLATE50,
        },
      }}
    >
      {children}
    </Menu>
  );

  return (
    <>
      <IconButton
        size="small"
        ref={null}
        aria-label="filter"
        aria-haspopup="true"
        onClick={onClickMenuButton}
        disabled={disabled}
        color="inherit"
        sx={{
          height: 48,
          width: 48,
          border: `1px solid ${COLORS.GREEN400}`,
          borderRadius: '0',
          backgroundColor: `${COLORS.NEUTRAL900}`,
          '&:hover *': {
            color: `${COLORS.NEUTRAL900}`,
          },
          '&:disabled': {
            backgroundColor: `${COLORS.NEUTRAL800}`,
            '& *': {
              opacity: '0.5',
            },
          },
        }}
      >
        { disabled ? <AutorenewIcon /> : iconOpen }
      </IconButton>
      {open && Popup}
    </>
  );
};

export default DropdownMenu;
