import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import COLORS from './styles/Colors';

const DatePicker = ({ label, value, onChange }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DesktopDatePicker
      label={label}
      disableFuture
      inputFormat="MM/DD/YYYY"
      value={value}
      onChange={(newValue) => onChange(new Date(newValue.$d))}
      renderInput={(params) => (
        <TextField
          sx={{
            flex: 1,
            maxHeight: '50px',
            minWidth: '170px',
            maxWidth: '300px',
            '& *': {
              color: COLORS.SLATE300,
            },
            input: {
              color: COLORS.SLATE300,
              height: '100%',
            },
            label: {
              color: COLORS.GREEN400,
              '&.Mui-focused': {
                color: COLORS.CYAN500,
              },
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: `2px solid ${COLORS.GREEN400} `,
              },
              '&:hover fieldset': {
                borderColor: COLORS.GREEN300,
              },
              '&.Mui-focused fieldset': {
                borderColor: COLORS.CYAN500,
              },
            },
          }}
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
        />
      )}
    />
  </LocalizationProvider>
);

export default DatePicker;
