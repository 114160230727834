/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import AppBar from './Components/AppBar';
import MainTable from './Components/CollapsibleTable';
import MainStyles from './Components/styles/GeneralStyles';
import InfoDialog from './Components/basics/InfoDialog';
import Alert from './Components/Alert';

import SECRETS from './secrets';
import {
  RUTASCACHE,
  USRCACHE,
  PUNTOSCACHE,
  RUTASIDS,
  UNITSCACHE,
} from './Config';
import { getFormatTimeString } from './util';

const { initBox } = MainStyles;

const twoDigits = (num) => {
  const numTwoDigits = (num <= 9) ? `0${num}` : `${num}`;
  return numTwoDigits;
};

const localeTimeString = (date) => getFormatTimeString(date);

const newTracksListDefault = () => ({
  current: [],
  finished: [],
  cancelled: [],
});

const HistoryScreen = ({
  socket,
  token,
  logOut,
  checkSession,
  secondary,
}) => {
  const [isLogged, setIsLogged] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [updatesTracksArray, setUpdatesTracksArray] = useState([]);
  const [unidadesArray, setUnidadesArray] = useState([]);
  const [usersArray, setUsersArray] = useState([]);
  const [pointsRoute, setPointsRoute] = useState({});
  const [currentTracks, setCurrentTracks] = useState([]);
  const [computedTracks, setComputedTracks] = useState([]);
  const [newTracks, setNewTracks] = useState([]);
  const [newTracksList, setNewTracksList] = useState({ ...newTracksListDefault() });
  const [isNewUptList, setIsNewUptList] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [searchBarTextDisplay, setSearchBarTextDisplay] = useState('');
  const [filtroRutas, setFiltroRutas] = useState([]);
  const [routesIdsList, setRoutesIdsList] = useState([]);
  const [visibleStateRows, setVisibleStateRows] = useState([2]);
  const [dateFilter, setDateFilter] = useState('');
  const [page, setPage] = useState(0);
  const [recoversList, setRecoversList] = useState([]);
  const [recoversCountMessage, setRecoversCountMessage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mainDataArray, setMainDataArray] = useState([]);
  const [infoDialogState, setInfoDialogState] = useState({
    isOpen: false,
    track: '',
    driver: '',
    unit: '',
    createdAt: '',
    initDate: '',
    initTime: '',
    end: '',
    mainDiffDisplay: '',
    stateRow: 0,
    checkerName: '',
    arrayTable: [],
  });
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [alertType, setAlertType] = useState('');
  const navigate = useNavigate();

  // Utils
  const getToday = () => {
    const date = new Date();
    return `${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`;
  };
  const isFilterStringToday = (dateFilter === getToday());

  const searchFilter = (row, searchValue) => {
    const removeTilde = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const stringToSearch = searchValue?.toLowerCase() ?? '';
    const matcher = new RegExp(`.*${removeTilde(stringToSearch)}.*`);
    const unidad = unidadesArray?.find((u) => u.idUnidad === row.idUnidad) ?? undefined;
    if (typeof unidad?.unidad !== 'undefined') {
      return (
        matcher.test(removeTilde(row?.nombreRuta?.toLowerCase() ?? ''))
        || matcher.test(removeTilde(unidad?.unidad?.toLowerCase() ?? ''))
        || matcher.test(removeTilde(row?.nombreOperador?.toLowerCase() ?? ''))
        || matcher.test(removeTilde(row?.checkerName?.toLowerCase() ?? ''))
      );
    }
    return (
      matcher.test(removeTilde(row?.nombreRuta?.toLowerCase() ?? ''))
      || matcher.test(removeTilde(row?.nombreOperador?.toLowerCase() ?? ''))
      || matcher.test(removeTilde(row?.checkerName?.toLowerCase() ?? ''))
    );
  };

  const stateRowObj = { cancelado: 0, activo: 1, terminado: 2 };
  const getStateRow = (cancel, finished) => {
    if (cancel) { return 'cancelado'; }
    if (finished) { return 'terminado'; }
    return 'activo';
  };

  const getCheckerName = (track) => {
    const checkerFind = usersArray
      ?.find((user) => user.idUsuario === track.idUsuario)?.nombre ?? undefined;
    const checkerName = (checkerFind === undefined) ? '' : checkerFind;
    return checkerName;
  };

  // Socket utils
  const findItemInNT = (idToFind) => newTracks?.find((item) => item.id === idToFind) ?? undefined;
  const stateObjItem = { cancelled: 0, current: 1, finished: 2 };

  const getVisibleProp = (selectedItem, stateRow) => (
    (filtroRutas?.includes(selectedItem?.idControlRuta) ?? false)
      && searchFilter(selectedItem, filterString)
      && (visibleStateRows?.includes(stateRow) ?? false)
  );

  // Main functions
  const setMainData = async () => {
    setAlertType('info');
    setMsg('Cargando...');
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    const tableFetch = await axios.post('/controlrecorrido', { fecha: dateFilter });
    const tableData = tableFetch.data;
    const tableArray = tableData.map((track) => {
      const stateRow = stateRowObj[getStateRow(track.cancelado, track.terminado)];
      const checkerName = getCheckerName(track);
      return ({
        ...track,
        puntos: track.puntos,
        track: track.nombreRuta,
        trackInit: track.inicio,
        driver: track.nombreOperador,
        editable: false,
        textFilter: true,
        selectFilter: true,
        lastPoint: '-',
        diff: '-',
        points: [],
        stateRow,
        checkerName,
      });
    }).sort((a, b) => a.trackInit - b.trackInit);
    if (getToday() === dateFilter) setNewTracks(tableArray);
    else setNewTracks([]);
    setCurrentTracks(tableArray);
    setIsLoadingTable(false);
    setMsg('Tabla cargada');
    setAlertType('success');
    setOpen(true);
  };

  const statusSocket = ({ status, recorrido }) => {
    const getNewTracksList = (field, prevList) => {
      const finalObj = {};

      Object.entries(prevList).forEach((objItem) => {
        const key = objItem[0];
        const selArray = objItem[1];
        const stateRow = stateObjItem[key];
        const isCurrent = Boolean(field === 'current');

        let selListToUpdate;
        if (key === field) {
          const defaultObj = { id: recorrido.id, visible: false };
          const findIdInArray = selArray?.find((track) => track.id === recorrido.id);
          selListToUpdate = (typeof findIdInArray === 'undefined')
            ? [...selArray, { ...defaultObj }]
            : [...selArray];
        } else {
          selListToUpdate = selArray.filter((track) => track.id !== recorrido.id);
        }

        const updateVisibleArray = selListToUpdate?.map((item) => item.id)
          ?.map((trackId) => {
            const isId = Boolean(recorrido.id === trackId);
            const selectedItem = (isCurrent && isId) ? recorrido : findItemInNT(trackId);
            const visible = getVisibleProp(selectedItem, stateRow);
            return { id: trackId, visible };
          })?.sort((a, b) => a.id - b.id) ?? [];
        finalObj[key] = [...updateVisibleArray];
      });
      return { ...finalObj };
    };

    if (status === 'nuevo') {
      if (routesIdsList.includes(recorrido.idControlRuta)) {
        console.log(`nuevo recorrido ${recorrido.id}`);
        const checkerName = getCheckerName(recorrido);
        setNewTracksList((prevValue) => getNewTracksList('current', prevValue));
        setNewTracks((prevState) => {
          const clone = [...prevState];
          return [
            {
              ...recorrido,
              editable: false,
              textFilter: true,
              track: recorrido.nombreRuta,
              trackInit: recorrido.inicio,
              driver: recorrido.nombreOperador,
              lastest: null,
              lastPoint: '-',
              diff: '-',
              list: [],
              points: [],
              selectFilter: true,
              stateRow: 1,
              checkerName,
            },
            ...clone,
          ];
        });
      }
    } else if ((status === 'cancelado' || status === 'terminado')) {
      setNewTracks((prevState) => {
        const clone = [...prevState];
        const index = clone?.findIndex((item) => (item.id === recorrido.id)) ?? -1;
        if (index > -1) {
          const { id: trackId } = clone[index];
          console.log(`recorrido ${status} ${trackId}`);
          const statusProp = (status === 'cancelado') ? 'cancelled' : 'finished';
          setNewTracksList((prevValue) => getNewTracksList(statusProp, prevValue));
          clone[index] = {
            ...clone[index],
            [status]: true,
            stateRow: stateRowObj[status],
          };
          return clone;
        }
        return clone;
      });
    }
  };

  // Side effects
  useEffect(() => { if (!checkSession()) setIsLogged(false); });

  useEffect(() => {
    // load rutas, usuarios, unidades, puntos from localStorage
    try {
      setRutas(JSON.parse(localStorage.getItem(RUTASCACHE)) || []);
    } catch (error) {
      console.error('rutas cache is corrupt');
    }
    try {
      setUsersArray(JSON.parse(localStorage.getItem(USRCACHE)) || []);
    } catch (error) {
      console.error('usuarios cache is corrupt');
    }
    try {
      setPointsRoute(JSON.parse(localStorage.getItem(PUNTOSCACHE)) || []);
    } catch (error) {
      console.error('puntos cache is corrupt');
    }
    try {
      const ids = JSON.parse(localStorage.getItem(RUTASIDS)) || [];
      setFiltroRutas(ids);
      setRoutesIdsList(ids);
    } catch (error) {
      console.error('rutas ids cache is corrupt');
    }
    setDateFilter(getToday());
    async function mainLoad() {
      let loginError = false;
      let orgIdError = false;
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      const routesFetch = await axios.get('/controlruta').catch((error) => {
        if (error.response) {
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
          if (error.response.status === 401) loginError = true;
        } else if (error.request) {
          console.error(error.request);
        }
      });
      const orgUsersFetch = await axios.get('/usuarios/organizacion').catch((error) => {
        if (error.response) {
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
          if (error.response.status === 401) orgIdError = true;
        } else if (error.request) {
          console.error(error.request);
        }
      });
      if (!loginError && !orgIdError) {
        const routesData = routesFetch.data;
        const orgUsersData = orgUsersFetch.data;
        const pointsByRouteObj = {};
        const routesIdsArray = routesData.map(({ id }) => id);
        const { data: pointsArray } = await axios.post('/controlpunto/all', { ids: routesIdsArray })
          .catch((e) => console.error(e));
        routesData.forEach(({ id }) => {
          pointsByRouteObj[id] = pointsArray.filter((point) => point.idControlRuta === id);
        });
        setRutas(routesData);
        localStorage.setItem(RUTASCACHE, JSON.stringify(routesData));
        setUsersArray(orgUsersData);
        localStorage.setItem(USRCACHE, JSON.stringify(orgUsersData));
        setPointsRoute(pointsByRouteObj);
        localStorage.setItem(PUNTOSCACHE, JSON.stringify(pointsByRouteObj));
        setFiltroRutas(routesIdsArray);
        setRoutesIdsList(routesIdsArray);
        localStorage.setItem(RUTASIDS, JSON.stringify(routesIdsArray));
      } else {
        setIsLogged(false);
      }
    }
    mainLoad();
    // socket effect
    let socketIdAssign = socket?.id;
    const loadDataSuccess = () => console.log(`Socket connected: ${socket.id}`);
    const socketDisconnect = (reason) => {
      console.log(`socket disconnect:: ${reason}`);
      setMsg('Conexión fallida');
      setAlertType('warning');
      setOpen(true);
    };
    const socketConnect = () => {
      socketIdAssign = socket.id;
      socket.emit('login', token);
      loadDataSuccess();
    };
    socket.on('connect', socketConnect);
    socket.on('disconnect', socketDisconnect);
    if (socketIdAssign) loadDataSuccess();
    else socket.connect();

    return (() => {
      socket.off('connect', socketConnect);
      socket.off('disconnect', socketDisconnect);
    });
  }, []);

  useEffect(() => {
    if (dateFilter && usersArray.length > 0) setMainData(dateFilter);
  }, [dateFilter]);

  useEffect(() => {
    if (!isLogged) {
      logOut();
      navigate('/');
    }
  }, [isLogged]);

  useEffect(() => {
    async function dateFilterEffect() {
      if (dateFilter !== '' && unidadesArray.length === 0) {
        setAlertType('info');
        setMsg('Cargando...');
        try {
          setUnidadesArray(JSON.parse(localStorage.getItem(UNITSCACHE)) || []);
        } catch (error) {
          console.error('unidades cache is corrupt');
        }
        axios.defaults.headers.common.Authorization = `bearer ${token}`;
        axios.defaults.baseURL = SECRETS.SERVERURL;
        const unitsOrgFetch = await axios.get('/unidades/organizacion');
        const unitsOrgData = unitsOrgFetch.data;
        const unitsSorted = unitsOrgData.sort((a, b) => a.unidad.localeCompare(b.unidad));
        setUnidadesArray(unitsSorted);
        localStorage.setItem(UNITSCACHE, JSON.stringify(unitsSorted));
      }
    }
    dateFilterEffect();
  }, [dateFilter]);

  // Special side effects
  useEffect(() => {
    const dataBeforeDebounce = currentTracks
      .filter((row) => filtroRutas.includes(row.idControlRuta))
      .filter((f) => visibleStateRows.includes(f.stateRow))
      .sort((a, b) => new Date(b.inicio) - new Date(a.inicio));
    const filteredData = dataBeforeDebounce.filter((row) => searchFilter(row, filterString));
    setMainDataArray(filteredData);
  }, [currentTracks, filterString, visibleStateRows, filtroRutas]);

  useEffect(() => {
    setNewTracksList((prevList) => {
      const finalObj = {};
      Object.entries(prevList).forEach((objItem) => {
        const key = objItem[0];
        const selArray = objItem[1];
        const stateRow = stateObjItem[key];

        const updateVisibleArray = selArray?.map((item) => item.id)
          ?.map((trackId) => {
            const selectedItem = findItemInNT(trackId);
            const visible = getVisibleProp(selectedItem, stateRow);
            return { id: trackId, visible };
          }).sort((a, b) => a.id - b.id);
        finalObj[key] = [...updateVisibleArray];
      });
      return { ...finalObj };
    });
    setPage(0);
  }, [filterString, visibleStateRows, filtroRutas]);

  useEffect(() => {
    if (isFilterStringToday) socket.on('status', statusSocket);
    return (() => {
      socket.off('status', statusSocket);
    });
  }, [
    routesIdsList,
    usersArray,
    dateFilter,
    filtroRutas,
    visibleStateRows,
    filterString,
    newTracks,
    newTracksList,
  ]);

  // Handles
  const handleUpdateTracks = async () => {
    try {
      setIsLoadingTable(true);
      const idsArray = Object
        ?.entries(newTracksList)
        ?.map((objItem) => objItem[1]).flat()
        ?.map((item) => item.id) ?? [];
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      const activeTracksIds = newTracks
        ?.filter((item) => item.stateRow === 1).map((item) => item.id) ?? [];
      const newTracksIds = newTracks.map((item) => item.id);
      const currentDate = new Date();
      const response = await axios.post('/controlpuntorecorrido/updateTracks', {
        idsArray,
        activeTracksIds,
        newTracksIds,
        dateFilter,
        currentDate,
      })
        .catch((reason) => {
          throw new Error(`handleUpdateTracks post error:: ${reason}`);
        });

      const activesChangesIds = [];
      const recoversCount = response?.data?.recoversCount ?? 0;
      const newTracksRecover = response?.data?.newTracksRecover ?? [];
      newTracksRecover.forEach((item) => activesChangesIds.push(item.id));
      const cloneNt = [...newTracksRecover, ...newTracks];

      const activesChangesRecover = [...response?.data?.activesChangesRecover ?? []];
      if (activesChangesRecover.length > 0) {
        activesChangesRecover.forEach((item) => {
          const selIdxInNt = cloneNt
            ?.findIndex((ntItem) => item.idControlRecorrido === ntItem.id) ?? -1;
          const statusChanges = (item.newStatus === 'terminado' || item.newStatus === 'cancelado');
          if (selIdxInNt > -1 && statusChanges) {
            cloneNt[selIdxInNt] = {
              ...cloneNt[selIdxInNt],
              [item.newStatus]: true,
              stateRow: stateRowObj[item.newStatus],
              puntos: [...item.pointsArray],
            };
            activesChangesIds.push(item.idControlRecorrido);
          }
        });
      }

      const newPointsObj = { ...response?.data?.groupedTp ?? {} };
      Object.keys(newPointsObj).forEach((key) => {
        const selIdxInNt = cloneNt.findIndex((ntItem) => parseInt(key, 10) === ntItem.id);
        if (selIdxInNt >= 0) {
          cloneNt[selIdxInNt].puntos = [...newPointsObj[key]];
        }
      });

      if (recoversCount > 0) {
        setNewTracks([...cloneNt]);
        setRecoversCountMessage(recoversCount);

        setTimeout(() => {
          setRecoversCountMessage(0);
        }, 5000);
      }
      setRecoversList([...activesChangesIds]);
      setCurrentTracks([...cloneNt]);
      setIsNewUptList(idsArray);
      setNewTracksList({ ...newTracksListDefault() });
      setIsLoadingTable(false);
    } catch (reason) {
      console.error(`"Control punto recorrido" method failed:: ${reason}`);
    }
  };

  const handleComputePoints = async (id, idUnidad, idControlRuta, detailPointsArray, initDate) => {
    try {
      setUpdatesTracksArray((prevState) => [...prevState, id]);
      const hourString = (initDate) ? getFormatTimeString(initDate) ?? '' : '';
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      const response = await axios.post('/controlpuntorecorrido/handleComputePoints', {
        id,
        idUnidad,
        idControlRuta,
        detailPointsArray,
        initDate,
        hourString,
      });
      setCurrentTracks((prevState) => {
        const clone = [...prevState];
        const indexTrack = clone.findIndex((item) => item.id === id);
        if (indexTrack > -1) clone[indexTrack].puntos = [...response?.data ?? []];
        return clone;
      });
      setUpdatesTracksArray((prevState) => prevState.filter((item) => item !== id));
      setComputedTracks((prevState) => [...prevState, id]);
    } catch (error) {
      throw new Error(`"Handle compute points" update failed:: ${error}`);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const debouncedFilter = useCallback(debounce((query) => {
    setFilterString(query);
  }, 500), []);

  const debouncedFilterString = (query) => {
    setSearchBarTextDisplay(query);
    debouncedFilter(query);
  };

  const handleInfoDialog = (newState) => (
    newState !== infoDialogState.isOpen && setInfoDialogState((currentState) => ({
      ...currentState,
      isOpen: newState,
    }))
  );

  const handlePanelInfoDialog = (trackId, arrayTable, mainDiffDisplay, row) => {
    const {
      track,
      driver,
      imei,
      createdAt,
      trackInit,
      fin,
      stateRow,
      idUsuario,
      comentario,
    } = row;
    const trackInitFmtDate = new Date(trackInit);
    setInfoDialogState((currentState) => ({
      ...currentState,
      trackId,
      track,
      driver,
      unit: `${unidadesArray.find((u) => `${u.imei}` === `${imei}`)?.unidad ?? ''}`,
      createdAt,
      initDate: trackInitFmtDate.toLocaleDateString('es-MX', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      initTime: localeTimeString(trackInitFmtDate),
      end: (fin) ? localeTimeString(new Date(fin)) : '',
      mainDiffDisplay,
      stateRow,
      checkerName: usersArray.find((user) => user.idUsuario === idUsuario)?.nombre ?? '',
      arrayTable,
      comentario,
    }));
    handleInfoDialog(true);
  };

  // Render
  return (
    <Container
      css={initBox}
      maxWidth={false}
      disableGutters
    >
      <InfoDialog
        infoDialogState={infoDialogState}
        handleClose={() => handleInfoDialog(false)}
        title="Detalle del recorrido"
        setCurrentTracks={setCurrentTracks}
        token={token}
      />
      <Alert open={open} setOpen={setOpen} msg={msg} alertType={alertType} />
      <AppBar
        visibleStateRows={visibleStateRows}
        dateFilter={dateFilter}
        filterRoutes={filtroRutas}
        rutas={rutas}
        newTracksList={newTracksList}
        searchBarTextDisplay={searchBarTextDisplay}
        secondary={secondary}
        handleSearch={debouncedFilterString}
        handleLogOut={() => setIsLogged(false)}
        handleStateRow={(arrayStateRows) => setVisibleStateRows(arrayStateRows)}
        handleFilterRoutes={(filter) => setFiltroRutas(filter)}
        handleDateFilter={(dateFilterValue) => setDateFilter(dateFilterValue)}
        handleUpdateTracks={handleUpdateTracks}
        recoversCountMessage={recoversCountMessage}
        isFilterStringToday={isFilterStringToday}
        location="Historial"
      />
      <MainTable
        mainDataArray={mainDataArray}
        checadas={[]}
        unidades={unidadesArray}
        secondary={(secondary === 'true')}
        pointsRoute={pointsRoute}
        visibleStateRows={visibleStateRows}
        page={page}
        rowsPerPage={rowsPerPage}
        liftingAction={undefined}
        setInfoInDialog={handlePanelInfoDialog}
        handlePageChange={(event, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleComputePoints={handleComputePoints}
        isNewUptList={isNewUptList}
        recoversList={recoversList}
        isLoadingTable={isLoadingTable}
        updatesTracksArray={updatesTracksArray}
        computedTracks={computedTracks}
        typeTable="historyScreen"
      />
    </Container>
  );
};

export default HistoryScreen;
