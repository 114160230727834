import React from 'react';
import { Box } from '@mui/system';
import { AppBar, Typography } from '@mui/material';
import MainMenu from './basics/Drawer';
import COLORS from './styles/Colors';

const styles = {
  container: {
    height: '100vh', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: 'max-content 1fr',
  },
  header: {
    position: 'sticky',
    minHeight: '72px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '1rem',
    padding: '8px 20px 8px 8px',
    background: COLORS.NEUTRAL900,
    flexWrap: 'wrap',
    '& *': {
      color: 'white',
    },
  },
  leftContent: {
    display: 'flex',
    flex: '1',
  },
  body: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
};

const TopBar = ({
  children,
  secondary,
  tools,
  handleLogOut,
  location,
}) => {
  'const';

  const Header = (
    <AppBar sx={styles.header}>
      <Box sx={styles.leftContent}>
        <MainMenu
          location={location}
          logOut={handleLogOut}
          secondary={secondary}
        />
        <Typography variant="h6" noWrap component="div" sx={{ alignSelf: 'center' }}>{location}</Typography>
      </Box>
      {tools}
    </AppBar>
  );

  return (
    <Box sx={styles.container}>
      {Header}
      <Box sx={styles.body}>
        {children}
      </Box>
    </Box>
  );
};

export default TopBar;
