import React from 'react';
import '@fontsource/roboto-mono/500.css';
import {
  Box,
  TableCell,
  TableRow,
  Button,
  Typography,
  IconButton,
  styled,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircleIcon from '@mui/icons-material/TripOriginOutlined';
import FinishedIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Close';
import DetailTrackIcon from '@mui/icons-material/InfoOutlined';
import InitTrackIcon from '@mui/icons-material/PunchClockOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import COLORS from './styles/Colors';
import './styles/LoaderStyles.css';
import { getFormatTimeString } from '../util';

const fontRobotoMono = { fontFamily: '"Roboto Mono", monospace' };
const tableBodyMainBorder = `2px solid ${COLORS.NEUTRAL400}`;
const boxStyles = {
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  padding: '0.125rem 0.5rem',
};
const tableCellBaseStyle = {
  borderBottom: tableBodyMainBorder,
  padding: '0 0.5rem',
};
const centeredDetailStyles = {
  width: '100%',
  textAlign: 'center',
  padding: 0,
};
const actionButtonsStyles = {
  fontSize: 14,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '6px',
  maxWidth: '6.5rem',
  textTransform: 'capitalize',
  '& .MuiButton-startIcon': {
    marginRight: '0.25rem',
  },
};
const detailMinutesStyles = {
  ...boxStyles,
  ...centeredDetailStyles,
  justifyContent: 'center',
  width: '3.5rem',
};

const MoreInfoTooltip = styled(({ className, ...props }) => (
  <Tooltip
    title={props.title}
    classes={{ popper: className }}
    disableTouchListener
    enterDelay={700}
    leaveDelay={150}
    placement="right"
  >
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: `${COLORS.NEUTRAL800}`,
    boxShadow: theme.shadows[1],
    backgroundColor: `${COLORS.NEUTRAL300}`,
    fontSize: 14,
  },
}));

const StyledIconButton = styled(IconButton)((props) => ({
  backgroundColor: `${props?.hascomment ? COLORS.RED100 : COLORS.EMERALD100}`,
  border: `2px solid ${props?.hascomment ? COLORS.RED700 : COLORS.EMERALD700}`,
  transition: 'all 0.25s ease-out',
  padding: '0.125rem 0.375rem',
  borderRadius: '0.375rem',
  '& .MuiSvgIcon-root': {
    color: `${props?.hascomment ? COLORS.RED700 : COLORS.EMERALD700}`,
  },
  '&:hover': {
    backgroundColor: `${props?.hascomment ? COLORS.RED200 : COLORS.EMERALD200}`,
    transition: 'all 0.25s ease-in',
    borderColor: `${props?.hascomment ? COLORS.RED800 : COLORS.EMERALD800}`,
    '& .MuiSvgIcon-root': {
      color: `${props?.hascomment ? COLORS.RED800 : COLORS.EMERALD800}`,
    },
  },
}));

const StyledHeaderDetailBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  color: `${COLORS.NEUTRAL700}`,
  backgroundColor: `${COLORS.NEUTRAL200}`,
  padding: '0.125rem 0',
  borderStyle: 'solid',
  borderColor: `${COLORS.NEUTRAL400}`,
  borderWidth: 0,
  borderBottomWidth: '2px',
}));
const StyledHeaderTextContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
const ButtonComputeTrack = styled(Button)(() => ({
  textTransform: 'none',
  color: COLORS.NEUTRAL900,
  transition: 'all 0.4s ease-out',
  padding: '4px 0',
  minWidth: '2.25rem',
  marginRight: '0.375rem',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: COLORS.NEUTRAL500,
  '&:hover': {
    transition: 'all 0.4s ease-in',
    backgroundColor: COLORS.NEUTRAL300,
  },
  '&:disabled': {
    backgroundColor: COLORS.STONE300,
    color: COLORS.STONE400,
    borderColor: COLORS.STONE400,
  },
}));
const StyledCachedIcon = styled(CachedIcon)(() => ({
  color: 'inherit',
  fontSize: '1.25rem',
  height: '100%',
}));

const StyledBodyDetailBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
}));
const StyledBoxDetailTrack = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}));
const StyledBoxLoader = styled(Box)(() => ({
  backgroundColor: 'white',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flex: '1 1 auto',
  height: '100%',
  width: '100%',
  zIndex: 35,
}));

const StyledTypoLoader = styled(Typography)(() => ({
  ...fontRobotoMono,
  marginTop: '0.625rem',
  fontSize: '1rem',
  color: COLORS.SLATE500,
}));

const StyledStatusWrapBox = styled(Box)(() => ({
  ...boxStyles,
  width: '7rem',
  paddingLeft: 0,
  marginLeft: '0.5rem',
}));
const StyledStatusEllipsisBox = styled(Box)(() => ({
  textOverflow: 'ellipsis',
  padding: 0,
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));
const StyledDetailHourBox = styled(Box)(() => ({ ...detailMinutesStyles }));
const StyledDetailDiffBox = styled(Box)(() => ({
  ...detailMinutesStyles,
  paddingRight: 0,
}));

const StyledActionButtonsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '0.5rem',
}));

const TrackPointsRow = ({
  row,
  secondary,
  checadas,
  unidades,
  pointsRoute,
  liftingAction,
  visibleStateRows,
  handleComputePoints,
  setInfoInDialog,
  comentario,
  isNewUptList,
  recoversList,
  updatesTracksArray,
  computedTracks,
  typeTable,
}) => {
  const {
    id,
    idUnidad,
    track,
    trackInit,
    idControlRuta,
    driver,
    imei,
    textFilter,
    selectFilter,
    stateRow,
    puntos,
    createdAt,
  } = row;
  const stateRowFilter = (visibleStateRows.includes(stateRow));
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };

  const getInitTrackObj = (dateTime) => {
    if (typeof dateTime === 'undefined') return undefined;
    const parsedDate = new Date(dateTime);
    const dateString = parsedDate.toLocaleString('es-MX', dateOptions);
    const displayHourInObj = getFormatTimeString(parsedDate);
    // Value (in minutes)
    const diff = Math.abs(new Date() - parsedDate);
    const minutesDiff = Math.floor((diff / 1000) / 60);
    const returnObj = { displayDate: dateString, minutesDiff, displayHourInObj };
    return returnObj;
  };

  const displayHour = getInitTrackObj(trackInit)?.displayHourInObj ?? '';
  const createdAtDate = new Date(createdAt);
  const createdAtDisplay = getFormatTimeString(createdAtDate);

  const stateRowObj = {
    0: { statusDisplay: 'cancelado', colorStatus: `${COLORS.RED600}` },
    1: { statusDisplay: 'activo', colorStatus: `${COLORS.GREEN600}` },
    2: { statusDisplay: 'terminado', colorStatus: `${COLORS.SKY700}` },
    3: { statusDisplay: '', colorStatus: `${COLORS.NEUTRAL700}` },
  };

  // Display values
  const splittedTrack = `${track}`?.split('-') ?? [];
  const splittedDriver = `${driver}`?.split(' ') ?? [];
  const unitDisplay = unidades.find((u) => `${u.imei}` === `${imei}`)?.unidad ?? '';
  const { statusDisplay, colorStatus } = stateRowObj?.[stateRow] ?? stateRowObj[3];

  // Styles
  const timeCustomStyles = {
    borderRight: tableBodyMainBorder,
    textTransform: 'capitalize',
    borderBottom: tableBodyMainBorder,
    '&.MuiTableCell-body': {
      padding: '0',
    },
  };

  const colorStatusObj = {
    success: COLORS.GREEN600,
    waiting: COLORS.AMBER600,
    completed: COLORS.ORANGE600,
    canceled: COLORS.RED600,
  };
  const getIsNewUptStyle = () => {
    const includesInArrays = (typeTable === 'historyScreen' && (isNewUptList.includes(id) || recoversList.includes(id)));
    return (includesInArrays) ? { backgroundColor: COLORS.LIME50 } : {};
  };

  const doubleDigitString = (number) => {
    if (number < 0) {
      return number > -10 ? `-0${number * -1}` : number;
    }
    return number < 10 ? `0${number}` : number;
  };

  const getTimeString = (minutes, type = '') => {
    if (typeof minutes === 'string') return minutes;
    const mins = minutes < 0 ? minutes * -1 : minutes;
    const min = Math.floor(mins);
    if (type === 'only minutes') {
      return `${doubleDigitString(min)}m`;
    }
    const sec = Math.round((mins - Math.floor(mins)) * 60);
    return `${doubleDigitString(min)}m${doubleDigitString(sec)}s`;
  };

  const parseToDisplayDiff = (diffValue) => {
    const intDiff = Math.round(diffValue);
    const stringDiff = (intDiff < 10) ? `0${Math.abs(intDiff)}` : `${Math.abs(intDiff)}`;
    const displayString = (intDiff < 0) ? `-${stringDiff}` : stringDiff;
    return displayString;
  };

  if (textFilter && selectFilter && stateRowFilter) {
    let diffValue = 0;
    if (typeTable === 'mainScreen') {
      if (typeof checadas[imei]?.latest?.diff === 'undefined') {
        if (puntos?.length > 0) {
          const lastOne = puntos.sort((a, b) => b.tiempo - a.tiempo)[0];
          const tiempoDB = pointsRoute[idControlRuta]
            ?.find((pr) => pr.id === lastOne.idControlPunto)?.tiempo;
          diffValue = ((new Date(lastOne.fecha) - new Date(trackInit))) / 1000 / 60 - tiempoDB;
        }
      } else {
        const tiempoDB = pointsRoute[idControlRuta]
          ?.find((pr) => pr.nombre === checadas[imei].latest.nombre)?.tiempo;
        const latestDate = checadas[imei].latest.date;
        diffValue = ((new Date(latestDate) - new Date(trackInit))) / 1000 / 60 - tiempoDB;
      }
    }
    if (typeTable === 'historyScreen' || typeTable === 'mainScreen') {
      diffValue = puntos?.reduce((acc, curr) => {
        if (typeof pointsRoute[idControlRuta] === 'undefined') {
          return acc;
        }
        const { tiempo: tiempoDB } = pointsRoute[idControlRuta]
          .find((pr) => pr.id === curr.idControlPunto);
        // Ignore last point in route
        // starts temp code
        const index = pointsRoute[idControlRuta]
          .sort((a, b) => a.tiempo - b.tiempo)
          .findIndex((pr) => pr.id === curr.idControlPunto);
        if (index === pointsRoute[idControlRuta].length - 1) {
          return acc;
        }
        // ends temp code
        const currDate = curr.fecha;
        const tiempoDiff = Math
          .floor(((new Date(currDate) - new Date(trackInit))) / 1000 / 60 - tiempoDB);
        return (tiempoDiff > 0 ? acc + tiempoDiff : acc);
      }, 0);
    }
    const mainDiffDisplay = (diffValue === undefined || (!diffValue)) ? '' : `${parseToDisplayDiff(diffValue)}`;
    let isComputeButtonVisible = false;
    const detailPointsArray = pointsRoute[idControlRuta]
      ?.sort((a, b) => a.tiempo - b.tiempo)
      ?.map((obj) => {
        let detailDiffColor = (diffValue && diffValue > 0) ? `${COLORS.RED600}` : `${COLORS.SKY600}`;
        const puntoChecada = checadas[imei]?.list?.find((l) => l.nombre === obj.nombre);
        const puntoChecadaDB = puntos?.find((p) => p.nombrePunto === obj.nombre);
        let pointHourDisplay = (puntoChecada) ? getInitTrackObj(puntoChecada?.date)?.displayHourInObj : '-';
        let requestPointHour = (puntoChecada) ? new Date(puntoChecada?.date).toTimeString().split(' ')[0] ?? '-' : '-';
        let diffDetailValue = (puntoChecada) ? puntoChecada?.diff : 0;
        let diffDetailDisplay = (puntoChecada) ? puntoChecada?.diff : '-';
        let elapsedTimeDisplay = (puntoChecada) ? (diffDetailValue + obj.tiempo) : '-';
        let colorCircle = (puntoChecada) ? 'success' : 'waiting';
        if (typeof puntoChecada?.date !== 'undefined') {
          elapsedTimeDisplay = (new Date(puntoChecada.date) - new Date(trackInit)) / 1000 / 60;
          diffDetailValue = Math.floor(elapsedTimeDisplay) - obj.tiempo;
          diffDetailDisplay = diffDetailValue;
          detailDiffColor = Math.floor(diffDetailValue) <= 0 ? `${COLORS.SKY600}` : `${COLORS.RED600}`;
        }
        if (typeof puntoChecadaDB !== 'undefined') {
          pointHourDisplay = (puntoChecadaDB) ? getInitTrackObj(puntoChecadaDB?.fecha)?.displayHourInObj : '-';
          requestPointHour = (puntoChecadaDB) ? new Date(puntoChecadaDB?.fecha).toTimeString().split(' ')[0] ?? '-' : '-';
          elapsedTimeDisplay = (new Date(puntoChecadaDB.fecha) - new Date(trackInit)) / 1000 / 60;
          diffDetailValue = Math.floor(elapsedTimeDisplay) - obj.tiempo;
          diffDetailDisplay = diffDetailValue;
          colorCircle = (puntoChecadaDB) ? 'success' : 'waiting';
          detailDiffColor = Math.floor(diffDetailValue) <= 0 ? `${COLORS.SKY600}` : `${COLORS.RED600}`;
        }
        const colorCircleStatus = `${colorStatusObj[colorCircle]}`;
        if (colorCircle !== 'success') isComputeButtonVisible = true;
        const trackPointDisplay = `${obj.nombre}`;
        const diffDisplay = getTimeString(diffDetailDisplay, 'only minutes');
        return ({
          id,
          pointStatus: { color: colorCircleStatus, display: trackPointDisplay },
          pointHourDisplay,
          requestPointHour,
          diff: { color: detailDiffColor, display: diffDisplay },
          timeDisplay: `${obj.tiempo}`,
          elapsedTimeDisplay: `${getTimeString(elapsedTimeDisplay)}`,
          diffDetailValue,
          idUnidad,
          idControlPunto: obj?.id ?? 0,
        });
      });

    const splittedTextInTableCell = (splittedTextArray, diffText, numCol) => (
      <TableCell padding="none" style={{ ...tableCellBaseStyle }} key={`splitted-cell-${id}-${numCol}`}>
        {(splittedTextArray.length) && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {splittedTextArray.map((item, indexItem) => {
              const indexSpan = indexItem;
              return (<span key={`splitted-text-${id}-${indexSpan}`}>{item}</span>);
            })}
            {!numCol && (
              <MoreInfoTooltip title="Detalle del recorrido">
                <StyledIconButton
                  color="inherit"
                  hascomment={comentario && comentario.length > 0}
                  onClick={() => setInfoInDialog(id, detailPointsArray, diffText, row)}
                >
                  <DetailTrackIcon sx={{ pointerEvents: 'none' }} />
                </StyledIconButton>
              </MoreInfoTooltip>
            )}
          </Box>
        )}
      </TableCell>
    );

    const regularTableCell = (cellType) => {
      const mainObj = {
        createdAt: {
          display: createdAtDisplay,
          align: 'left',
          customStyle: {},
          numCol: 2,
        },
        unit: {
          display: unitDisplay,
          align: 'right',
          customStyle: { fontWeight: 'bold' },
          numCol: 3,
        },
        statusTrack: {
          display: statusDisplay,
          align: 'left',
          customStyle: { color: colorStatus },
          numCol: 4,
        },
        defaultValues: {
          display: '',
          align: '',
          customStyle: {},
          numCol: -1,
        },
      };
      const mainValue = mainObj?.[cellType] ?? mainObj.defaultValues;
      const {
        display,
        align,
        customStyle,
        numCol,
      } = mainValue;
      return (
        <TableCell
          padding="none"
          key={`regular-cell-${id}-${numCol}`}
          align={align}
          sx={{
            ...customStyle,
            borderBottom: tableBodyMainBorder,
            '&.MuiTableCell-body': {
              padding: '0.125rem 0.5rem',
            },
          }}
        >
          {display}
        </TableCell>
      );
    };

    const TotalCell = () => {
      const groups = detailPointsArray?.reduce((acc, curr) => {
        const { diffDetailValue: value } = curr;

        if (value === 0) return acc;

        return {
          ...acc,
          ...(
            Math.floor(value) >= 0
              ? { delay: [...acc.delay, value] }
              : { advance: [...acc.advance, value] }
          ),
        };
      }, { delay: [], advance: [] });

      const delay = groups.delay.reduce((acc, curr) => acc + curr, 0);
      /* TODO: Column Total
      const advance = groups.advance.reduce((acc, curr) => acc + curr, 0);

      const data = [
        { label: 'Atraso', value: delay, color: COLORS.RED600 },
        { label: 'Adelanto', value: advance, color: COLORS.SKY600 },
        { label: 'Diferencia', value: delay - advance, color: COLORS.NEUTRAL700 },
      ];

      return (
        <TableCell sx={timeCustomStyles}>
          {data.map((item, index) => (
            <div key={`${index + 1} a`} style={{ padding: ' 0 16px 40px 16px' }}>
              <Typography variant="body2" sx={{ color: item.color }}>
                {item.label}
              </Typography>
              <Typography variant="body2" sx={{ color: item.color }}>
                {getTimeString(item.value, 'only minutes')}
              </Typography>
            </div>
          ))}
        </TableCell>
      ); */

      return (
        <TableCell sx={timeCustomStyles}>
          <Typography variant="body2" sx={{ color: COLORS.RED600, textAlign: 'center' }}>
            {getTimeString(delay, 'only minutes')}
          </Typography>
        </TableCell>
      );
    };

    const actionButtonGenerator = (buttonType) => {
      const mainObj = {
        finish: {
          display: 'Terminar',
          color: `${COLORS.SKY700}`,
          backgroundColor: `${COLORS.SKY100}`,
          hoverColor: `${COLORS.SKY800}`,
          hoverBackgroundColor: `${COLORS.SKY200}`,
          icon: <FinishedIcon fontSize="small" />,
          onClickKey: 'terminado',
        },
        cancel: {
          display: 'Cancelar',
          color: `${COLORS.RED700}`,
          backgroundColor: `${COLORS.RED100}`,
          hoverColor: `${COLORS.RED800}`,
          hoverBackgroundColor: `${COLORS.RED200}`,
          icon: <CancelIcon fontSize="small" />,
          onClickKey: 'cancelado',
        },
        default: {
          display: '',
          color: `${COLORS.NEUTRAL700}`,
          backgroundColor: `${COLORS.NEUTRAL100}`,
          hoverColor: `${COLORS.NEUTRAL800}`,
          hoverBackgroundColor: `${COLORS.NEUTRAL200}`,
          icon: null,
          onClickKey: '',
        },
      };
      const {
        display,
        backgroundColor,
        color,
        hoverColor,
        hoverBackgroundColor,
        icon,
        onClickKey,
      } = mainObj?.[buttonType] ?? mainObj.default;
      return (
        <Button
          size="small"
          startIcon={icon}
          onClick={() => liftingAction(id, onClickKey)}
          color="inherit"
          sx={{
            ...actionButtonsStyles,
            marginBottom: (buttonType === 'finish') ? '0.875rem' : 0,
            color,
            backgroundColor,
            borderColor: color,
            '&:hover': {
              color: hoverColor,
              backgroundColor: hoverBackgroundColor,
            },
          }}
        >
          {display}
        </Button>
      );
    };

    const renderRow = (
      <TableRow key={`row-${id}`} sx={{ ...getIsNewUptStyle() }}>
        {splittedTextInTableCell(splittedTrack, mainDiffDisplay, 0)}
        {splittedTextInTableCell(splittedDriver, '', 1)}
        {regularTableCell('createdAt')}
        {regularTableCell('unit')}
        {(typeTable === 'historyScreen') && (regularTableCell('statusTrack'))}
        <TotalCell />
        <TableCell key={`detail-cell-${id}`} style={{ padding: 0 }} sx={{ ...tableCellBaseStyle }}>
          <StyledHeaderDetailBox key={`detail-interior-box-${id}-header`}>
            <StyledHeaderTextContainer key={`styled-header-text-container-${id}`}>
              <InitTrackIcon sx={{ marginLeft: '0.375rem', marginRight: '0.5rem' }} />
              Salida:
              <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'bold' }}>
                {`${displayHour}` ?? ''}
              </Typography>
            </StyledHeaderTextContainer>
            {(typeTable === 'historyScreen'
            && isComputeButtonVisible
            && !secondary
            && !computedTracks.includes(id)
            && stateRow === 2) && (
              <ButtonComputeTrack
                color="inherit"
                onClick={() => handleComputePoints(
                  id,
                  idUnidad,
                  idControlRuta,
                  detailPointsArray,
                  new Date(trackInit),
                )}
                disableElevation
                disabled={(updatesTracksArray.includes(id))}
                size="small"
              >
                <StyledCachedIcon />
              </ButtonComputeTrack>
            )}
          </StyledHeaderDetailBox>
          <StyledBodyDetailBox>
            <StyledBoxDetailTrack>
              {detailPointsArray?.map(({
                pointStatus,
                pointHourDisplay,
                diff,
              }, index) => {
                const boxIndex = index;
                return (
                  <Box key={`detail-interior-box-${id}-${boxIndex}`} sx={{ display: 'flex', alignItems: 'center' }}>
                    <StyledStatusWrapBox key={`detail-box-${id}-0`}>
                      <CircleIcon fontSize="small" sx={{ color: pointStatus.color, mr: '0.375rem', fontSize: '0.875rem' }} />
                      <StyledStatusEllipsisBox>{pointStatus.display}</StyledStatusEllipsisBox>
                    </StyledStatusWrapBox>
                    <StyledDetailHourBox key={`detail-box-${id}-1`}>{pointHourDisplay}</StyledDetailHourBox>
                    <StyledDetailDiffBox key={`detail-box-${id}-2`} sx={{ color: diff.color }}>
                      {diff.display}
                    </StyledDetailDiffBox>
                  </Box>
                );
              })}
              {(updatesTracksArray && updatesTracksArray.includes(id)) && (
                <StyledBoxLoader>
                  <div className="loader-spinner" />
                  <StyledTypoLoader>
                    Cargando...
                  </StyledTypoLoader>
                </StyledBoxLoader>
              )}
            </StyledBoxDetailTrack>
            {typeTable === 'mainScreen' && (
              <StyledActionButtonsBox key={`detail-interior-box-${id}-buttons`}>
                {actionButtonGenerator('finish')}
                {actionButtonGenerator('cancel')}
              </StyledActionButtonsBox>
            )}
          </StyledBodyDetailBox>
        </TableCell>
      </TableRow>
    );
    return renderRow;
  }
  return false;
};

export default TrackPointsRow;
