import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import {
  styled,
  Box,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  InputAdornment,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TrackIcon from '@mui/icons-material/MapOutlined';
import DriverIcon from '@mui/icons-material/BadgeOutlined';
import CheckerIcon from '@mui/icons-material/ThreePOutlined';
import CreatedAtIcon from '@mui/icons-material/MoreTimeOutlined';
import CombiIcon from '@mui/icons-material/AirportShuttleOutlined';
import DateIcon from '@mui/icons-material/TodayOutlined';
import InitTrackIcon from '@mui/icons-material/PunchClockOutlined';
import ClockIcon from '@mui/icons-material/AccessTimeOutlined';
import StatusIcon from '@mui/icons-material/InfoOutlined';
import CircleIcon from '@mui/icons-material/TripOriginOutlined';
import COLORS from '../styles/Colors';
import Alert from '../Alert';

import SECRETS from '../../secrets';
import { getFormatTimeString } from '../../util';

const plSm1 = { pl: { xs: 0, sm: 1 } };
const prSm1 = { pr: { xs: 0, sm: 1 } };
const plMd1 = { pl: { xs: 0, md: 1 } };
const prMd1 = { pr: { xs: 0, md: 1 } };

const borderBottom = '1px solid rgb(188, 188, 188)';
const pointStatusBoxStyles = {
  display: 'flex',
  alignItems: 'center',
};

const tableHeadStyle = {
  backgroundColor: `${COLORS.ZINC50}`,
  color: `${COLORS.ZINC900}`,
  fontWeight: 'bold',
  borderBottom: `2px solid ${COLORS.ZINC900}`,
  '&.MuiTableCell-head': {
    padding: '0.25rem 0.5rem',
  },
};

const StyledDialogTitle = styled(DialogTitle)(() => ({
  width: 'calc(100% - 6rem)',
}));

const StyledTableBox = styled(Box)(() => ({
  position: 'relative',
  paddingTop: '2rem',
  paddingBottom: '0.25rem',
  overflowX: 'auto',
}));

const actionButtonsStyles = {
  fontSize: 14,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '6px',
  maxWidth: '6.5rem',
  textTransform: 'capitalize',
};

const Colors = {
  color: `${COLORS.TEAL700}`,
  backgroundColor: `${COLORS.TEAL100}`,
  hoverColor: `${COLORS.TEAL800}`,
  hoverBackgroundColor: `${COLORS.TEAL200}`,
};

const localeTimeString = (date) => getFormatTimeString(date);

const dateObj = {
  '01': 'Enero',
  '02': 'Febrero',
  '03': 'Marzo',
  '04': 'Abril;',
  '05': 'Mayo',
  '06': 'Junio',
  '07': 'Julio',
  '08': 'Agosto',
  '09': 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre',
};

const InfoDialog = ({
  infoDialogState,
  handleClose,
  title,
  setCurrentTracks,
  token,
}) => {
  const [formComentario, setFormComentario] = useState(infoDialogState.comentario);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    setFormComentario(infoDialogState.comentario);
  }, [infoDialogState]);

  const sxBorderBottom = { borderBottomWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: `${COLORS.ZINC400}` };
  const {
    isOpen,
    track,
    driver,
    unit,
    createdAt,
    initDate,
    initTime,
    end,
    mainDiffDisplay,
    stateRow,
    arrayTable,
    checkerName,
    comentario,
    trackId,
  } = infoDialogState;
  const stateRowObj = {
    0: { status: 'Cancelado', color: `${COLORS.RED600}` },
    1: { status: 'Activo', color: `${COLORS.GREEN600}` },
    2: { status: 'Terminado', color: `${COLORS.SKY700}` },
  };
  const { status, color } = stateRowObj[stateRow];
  const createdAtDate = new Date(createdAt);

  const saveNewTrack = (id) => {
    setCurrentTracks((tracks) => {
      const newTracks = [...tracks];
      const index = newTracks.findIndex((t) => t.id === id);
      if (index >= 0) {
        newTracks[index].comentario = formComentario;
        axios.defaults.headers.common.Authorization = `bearer ${token}`;
        axios.defaults.baseURL = SECRETS.SERVERURL;
        axios
          .patch(`/controlrecorrido/${id}`, {
            comentario: formComentario,
          })
          .then((rsp) => {
            console.log(rsp);
            if (rsp.status === 200) {
              setAlertType('success');
              setMsg('Nuevos datos guardados');
              setOpen(true);
            } else {
              setAlertType('error');
              setMsg('Ocurrió un error, intenta nuevamente más tarde.');
              setOpen(true);
            }
          })
          .catch((e) => {
            setAlertType('error');
            setMsg('Ocurrió un error, intenta nuevamente más tarde.');
            setOpen(true);
            console.error(e);
          });
      }
      return newTracks;
    });
  };

  const textField = (inputType) => {
    const mainObj = {
      track: {
        value: track,
        icon: <TrackIcon />,
        grid: { xs: 12, md: 4 },
        padding: { ...prMd1 },
        label: 'Recorrido',
      },
      checkerName: {
        value: checkerName,
        icon: <CheckerIcon />,
        grid: { xs: 12, sm: 6, md: 4 },
        padding: { pt: { xs: 2, md: 0 }, ...plMd1, ...prSm1 },
        label: 'Checador',
      },
      driver: {
        value: driver,
        icon: <DriverIcon />,
        grid: { xs: 12, sm: 6, md: 4 },
        padding: { pt: { xs: 2, md: 0 }, ...plSm1 },
        label: 'Conductor',
      },
      initDate: {
        value: `${initDate.slice(0, 2)} de ${dateObj[initDate.slice(3, 5)]} de ${initDate.slice(-4)}`,
        icon: <DateIcon />,
        grid: { xs: 12, sm: 6, md: 6 },
        padding: { pt: 2, ...prSm1 },
        label: 'Fecha',
      },
      createdAt: {
        value: localeTimeString(createdAtDate),
        icon: <CreatedAtIcon />,
        grid: { xs: 6, sm: 3, md: 3 },
        padding: { pt: 2, pr: 1, ...plSm1 },
        label: 'Registro',
      },
      unit: {
        value: unit,
        icon: <CombiIcon />,
        grid: { xs: 6, sm: 3, md: 3 },
        padding: { pt: 2, pl: 1, pr: { sm: 1, md: 0 } },
        label: 'Unidad',
      },
      initTime: {
        value: initTime,
        icon: <InitTrackIcon />,
        grid: { xs: 6, sm: 6, md: 3 },
        padding: { pt: 2, pr: 1, pl: { sm: 1, md: 0 } },
        label: 'Inicio',
      },
      end: {
        value: end,
        icon: <InitTrackIcon />,
        grid: { xs: 6, sm: 6, md: 3 },
        padding: { pt: 2, pl: 1, ...prMd1 },
        label: 'Fin',
      },
      'state-row': {
        value: status,
        icon: <StatusIcon />,
        grid: { xs: 6, md: 3 },
        padding: { pt: 2, ...plMd1, pr: 1 },
        label: 'Status',
      },
      'main-diff': {
        value: `${(!mainDiffDisplay) ? '00' : mainDiffDisplay}m`,
        icon: <ClockIcon />,
        grid: { xs: 6, md: 3 },
        padding: { pt: 2, pl: 1 },
        label: 'Atraso',
      },
      comentario: {
        value: `${comentario || ''}`,
        icon: <StatusIcon />,
        grid: { xs: 12, md: 12 },
        padding: { pt: 2, pl: 1 },
        label: 'Comentario',
      },
    };
    const grid = mainObj[inputType]?.grid ?? {};
    const padding = mainObj[inputType]?.padding ?? {};
    const displayLabel = mainObj[inputType]?.label ?? '';
    const displayValue = mainObj[inputType]?.value ?? '';
    const icon = mainObj[inputType]?.icon ?? null;
    const sxStatusField = (inputType === 'state-row') ? { '& .MuiInput-root': { color } } : {};
    const colorDiff = (!mainDiffDisplay) ? COLORS.SKY700 : COLORS.RED700;
    const sxMainDiffField = (inputType === 'main-diff') ? { '& .MuiInput-root': { color: colorDiff } } : {};
    return (
      <Grid
        item
        xs={((grid?.xs) ? grid.xs : undefined)}
        sm={((grid?.sm) ? grid.sm : undefined)}
        md={((grid?.md) ? grid.md : undefined)}
      >
        <Box sx={{ ...padding, display: 'flex' }}>
          {inputType !== 'comentario' && (
            <TextField
              id={`input-info-dialog-${inputType}`}
              label={displayLabel}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {icon}
                  </InputAdornment>
                ),
                readOnly: true,
                value: displayValue,
              }}
              variant="standard"
              sx={{ width: '100%', ...sxStatusField, ...sxMainDiffField }}
            />
          )}
          {inputType === 'comentario' && (
            <TextField
              id={`input-info-dialog-${inputType}`}
              label={displayLabel}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {icon}
                  </InputAdornment>
                ),
                readOnly: false,
                value: formComentario,
                onChange: (e) => {
                  const { value } = e.target;
                  if (value.length > 99) {
                    setFormComentario(value.substring(0, 99));
                  } else {
                    setFormComentario(e.target.value);
                  }
                },
              }}
              maxlength="10"
              variant="standard"
              sx={{ width: '100%', ...sxStatusField, ...sxMainDiffField }}
            />
          )}
          {inputType === 'comentario' && (
            <Button
              size="small"
              onClick={() => saveNewTrack(trackId)}
              color="inherit"
              sx={{
                ...actionButtonsStyles,
                grid: { xs: 1, md: 1 },
                marginBottom: 0,
                color: Colors.color,
                backgroundColor: Colors.backgroundColor,
                borderColor: Colors.color,
                '&:hover': {
                  color: Colors.hoverColor,
                  backgroundColor: Colors.hoverBackgroundColor,
                },
              }}
            >
              Guardar
            </Button>
          )}
        </Box>
      </Grid>
    );
  };

  const cellHeader = (display, align, numItem) => (
    <TableCell
      padding="none"
      key={`table-head-info-dialog-${numItem}`}
      align={align}
      sx={{ ...tableHeadStyle }}
    >
      {display}
    </TableCell>
  );

  const cellBody = (display, columnType, idxKey, idxMap, numItem, align, ...colors) => {
    const colorPoint = colors[0];
    const colorDiff = colors[1];
    const cellPadding = (columnType === 'track') ? {} : { padding: '0.125rem 0.5rem' };
    const displayInCellObj = {
      track: (
        <Box sx={{ ...pointStatusBoxStyles, padding: '0.125rem 0.5rem' }}>
          <CircleIcon fontSize="small" sx={{ color: colorPoint, mr: '0.25rem', fontSize: '0.875rem' }} />
          <Typography variant="body2" noWrap sx={{ padding: 0, width: '100%' }}>{display}</Typography>
        </Box>
      ),
      hour: display,
      diff: (<Typography variant="body2" noWrap sx={{ color: colorDiff }}>{display}</Typography>),
      time: display,
      elapsedTime: display,
    };
    const displayInCell = displayInCellObj[columnType] ?? null;
    return (
      <TableCell
        padding="none"
        key={`table-cell-info-dialog-${idxKey}-${idxMap}-${numItem}`}
        align={align}
        sx={{ borderBottom, '&.MuiTableCell-body': { ...cellPadding } }}
      >
        {displayInCell}
      </TableCell>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-info"
      fullWidth
      maxWidth="lg"
    >
      <Box
        sx={{
          ...sxBorderBottom,
          display: 'flex',
          backgroundColor: `${COLORS.ZINC100}`,
          color: `${COLORS.ZINC900}`,
        }}
      >
        <StyledDialogTitle id="alert-dialog-info">{title}</StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="inherit"
          sx={{ color: `${COLORS.ZINC900}` }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Grid container spacing={0}>
          {textField('track')}
          {textField('checkerName')}
          {textField('driver')}
          {textField('initDate')}
          {textField('createdAt')}
          {textField('unit')}
          {textField('initTime')}
          {textField('end')}
          {textField('state-row')}
          {textField('main-diff')}
          {textField('comentario')}
        </Grid>
        <StyledTableBox>
          <Table>
            <TableHead>
              <TableRow>
                {cellHeader('Punto', 'left', '0')}
                {cellHeader('Hora', 'left', '1')}
                {cellHeader('Diff.', 'left', '2')}
                {cellHeader('Est.', 'right', '3')}
                {cellHeader('Tiempo', 'left', '4')}
              </TableRow>
            </TableHead>
            <TableBody>
              {arrayTable?.map(({
                keyIndex,
                pointStatus: { color: statusColor, display: statusDisplay },
                pointHourDisplay,
                diff: { color: diffColor, display: diffDisplay },
                timeDisplay,
                elapsedTimeDisplay,
              }, idx) => {
                const idxMapScope = idx;
                return (
                  <TableRow key={`table-row-info-dialog-${keyIndex}-${idxMapScope}`}>
                    {cellBody(statusDisplay, 'track', keyIndex, idxMapScope, '0', 'left', statusColor, diffColor)}
                    {cellBody(pointHourDisplay, 'hour', keyIndex, idxMapScope, '1', 'left', statusColor, diffColor)}
                    {cellBody(diffDisplay, 'diff', keyIndex, idxMapScope, '2', 'left', statusColor, diffColor)}
                    {cellBody(timeDisplay, 'time', keyIndex, idxMapScope, '3', 'right', statusColor, diffColor)}
                    {cellBody(elapsedTimeDisplay, 'elapsedTime', keyIndex, idxMapScope, '4', 'left', statusColor, diffColor)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableBox>

      </DialogContent>
      <Alert open={open} setOpen={setOpen} msg={msg} alertType={alertType} />
    </Dialog>
  );
};

export default InfoDialog;
