import React from 'react';
import { Divider as MuiDivider } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDivider = styled(MuiDivider)(() => ({
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'rgba(255, 255, 255, 0.5)',
  borderBottomWidth: 'inherit',
}));

const Divider = () => <StyledDivider variant="middle" light />;

export default Divider;
