import React, { useState, useRef, forwardRef } from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import TrackFilterOffIcon from '@mui/icons-material/RemoveRoadOutlined';
import TrackFilterOnIcon from '@mui/icons-material/EditRoadOutlined';
import StateRowFilterOnIcon from '@mui/icons-material/FilterListOutlined';
import StateRowFilterOffIcon from '@mui/icons-material/FilterListOffOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import RestoreIcon from '@mui/icons-material/Restore';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import DateField from './basics/DatePicker';
import MainStyles from './styles/GeneralStyles';
import MainMenu from './basics/Drawer';
import FilterMenu from './FilterMenu';

import COLORS from './styles/Colors';

const { headerBox } = MainStyles;

const radius = '.375rem';
const radiusLeft = '.375rem 0 0 .375rem';
const radiusRight = '0 .375rem .375rem 0';

const textFieldInputStyles = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  height: '100%',
  color: `${COLORS.SLATE200}`,
  borderStyle: 'solid',
  borderRightWidth: '1px',
  margin: 0,
};

const textFieldInputDOMStyles = {
  padding: '0 1rem',
  paddingLeft: '3rem',
  height: '100%',
};

const styledTypography = (theme) => ({
  fontSize: 18,
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    width: '33%',
  },
});

const DivHeader = styled('div')(() => ({
  ...headerBox,
  zIndex: 10,
}));

const StyledSearchInput = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  height: 48,
  marginRight: 0,
  marginLeft: 0,
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  height: '100%',
  paddingBottom: 0,
  '& .Mui-focused': {
    '&.MuiInputBase-root': {
      borderColor: `${COLORS.CYAN500}`,
      borderWidth: '2px',
      borderRightWidth: '2px !important',
      borderLeftWidth: '2px !important',
    },
  },
}));

const StyledAppBar = styled(AppBar)(() => ({
  backgroundColor: `${COLORS.NEUTRAL900}`,
  color: `${COLORS.SLATE50}`,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: '0.5rem',
  [theme.breakpoints.up('sm')]: {
    padding: '0.5rem 0.5rem 0',
  },
}));

const StyledNewsGrid = styled(Grid)(({ newsitemscount, theme }) => {
  const minHeightObj = {
    0: '2.5rem',
    1: '2.5rem',
    2: '3.5rem',
    3: '5rem',
  };
  return ({
    cursor: 'pointer',
    backgroundColor: `${COLORS.ZINC800}`,
    padding: '8px 24px',
    minHeight: minHeightObj?.[newsitemscount] ?? '5rem',
    [theme.breakpoints.up('md')]: {
      minHeight: 'auto',
    },
  });
});

const StyledNewsMainCont = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledDivContStates = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 3rem)',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const StyledTypoGreen = styled(Typography)(({ theme }) => ({
  ...styledTypography(theme),
  color: `${COLORS.GREEN300}`,
}));
const StyledTypoBlue = styled(Typography)(({ theme }) => ({
  ...styledTypography(theme),
  color: `${COLORS.SKY300}`,
}));
const StyledTypoRed = styled(Typography)(({ theme }) => ({
  ...styledTypography(theme),
  color: `${COLORS.RED300}`,
}));
const StyledTypoDefault = styled(Typography)(({ theme }) => ({
  ...styledTypography(theme),
  color: `${COLORS.NEUTRAL300}`,
}));

const StyledBoxUptButton = styled(Typography)(() => ({
  width: '2.75rem',
  height: '2.75rem',
  border: `2px solid ${COLORS.AMBER400}`,
  borderRadius: '6px',
  textAlign: 'center',
  transition: 'all 0.5s ease-out',
  '&:hover': {
    backgroundColor: `${COLORS.ZINC600}`,
    transition: 'all 0.5s ease-in',
  },
}));

const StyledCachedIcon = styled(CachedIcon)(() => ({
  color: `${COLORS.AMBER200}`,
  fontSize: '2.375rem',
  height: '100%',
}));

const StyledTooltipUpt = styled(({ className, ...props }) => (
  <Tooltip
    title={props.title}
    classes={{ popper: className }}
    disableTouchListener
    enterDelay={700}
    leaveDelay={150}
    placement="right"
  >
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: `${COLORS.NEUTRAL700}`,
    boxShadow: theme.shadows[1],
    backgroundColor: `${COLORS.BLUE100}`,
    fontSize: 18,
  },
}));

// Filter icons
const StyledIconTrackFilterOn = styled(TrackFilterOnIcon)(() => ({ color: `${COLORS.GREEN400}` }));
const StyledIconTrackFilterOff = styled(TrackFilterOffIcon)(() => ({ color: `${COLORS.ZINC400}` }));

const StyledIconStateRowFilterOn = styled(StateRowFilterOnIcon)(() => ({ color: `${COLORS.GREEN400}` }));
const StyledIconStateRowFilterOff = styled(StateRowFilterOffIcon)(() => ({ color: `${COLORS.ZINC400}` }));

// New Tracks Utils
const getNewsByCategoryLen = (newTracksList) => {
  const getArrayLength = (stateArray) => {
    if (Array.isArray(stateArray) && stateArray.length > 0) {
      const filteredIds = stateArray
        ?.filter((item) => item.visible)
        ?.map((item) => item.id) ?? [];
      return filteredIds.length;
    }
    return 0;
  };
  const finishedLength = getArrayLength(newTracksList?.finished ?? []);
  const currentLength = getArrayLength(newTracksList?.current ?? []);
  const cancelledLength = getArrayLength(newTracksList?.cancelled ?? []);
  return { finishedLength, currentLength, cancelledLength };
};

const getNewsItemCount = (newTracksList) => {
  let count = 0;
  const { currentLength, finishedLength, cancelledLength } = getNewsByCategoryLen(newTracksList);
  if (currentLength > 0) count += 1;
  if (finishedLength > 0) count += 1;
  if (cancelledLength > 0) count += 1;
  return count;
};

// New Tracks Updates Bar
const NewsBar = forwardRef(({ newTracksList, recoversCountMessage }, ref) => {
  const { currentLength, finishedLength, cancelledLength } = getNewsByCategoryLen(newTracksList);
  const notNewTracks = (currentLength === 0 && finishedLength === 0 && cancelledLength === 0);
  const getStateMessage = (statusMsg) => {
    const messageObj = {
      arrayLen: {
        current: currentLength,
        finished: finishedLength,
        cancelled: cancelledLength,
        refresh: 0,
        recover: recoversCountMessage,
        default: 0,
      },
      icon: {
        current: AddRoadIcon,
        finished: EditRoadIcon,
        cancelled: RemoveRoadIcon,
        refresh: RestoreIcon,
        recover: RestoreIcon,
        default: InfoIcon,
      },
      font: {
        current: StyledTypoGreen,
        finished: StyledTypoBlue,
        cancelled: StyledTypoRed,
        refresh: StyledTypoDefault,
        recover: StyledTypoDefault,
        default: StyledTypoDefault,
      },
    };
    const IconMsg = messageObj?.icon?.[statusMsg] ?? messageObj.icon.default;
    const arrayLenMsg = messageObj?.arrayLen?.[statusMsg] ?? messageObj.arrayLen.default;
    const FontMsg = messageObj?.font?.[statusMsg] ?? messageObj.font.default;
    const trackWord = (arrayLenMsg > 1) ? 'recorridos' : 'recorrido';
    let statusWord = '';
    switch (statusMsg) {
      case 'current': statusWord = (currentLength === 1) ? 'nuevo' : 'nuevos'; break;
      case 'finished': statusWord = (finishedLength === 1) ? 'terminado' : 'terminados'; break;
      case 'cancelled': statusWord = (cancelledLength === 1) ? 'cancelado' : 'cancelados'; break;
      case 'recover': statusWord = (recoversCountMessage === 1) ? 'recuperado' : 'recuperados'; break;
      default: break;
    }
    return (
      <FontMsg sx={{ display: 'flex' }}>
        <IconMsg sx={{ fontSize: '1.5rem', marginRight: '0.5rem' }} />
        {(recoversCountMessage > 0 || !notNewTracks) && `${arrayLenMsg} ${trackWord} ${statusWord}`}
        {(recoversCountMessage === 0 && notNewTracks) && 'Actualizar recorridos'}
      </FontMsg>
    );
  };

  return (
    <StyledNewsMainCont ref={ref}>
      <StyledDivContStates>
        {(recoversCountMessage > 0) && getStateMessage('recover')}
        {(recoversCountMessage === 0 && currentLength > 0) && getStateMessage('current')}
        {(recoversCountMessage === 0 && finishedLength > 0) && getStateMessage('finished')}
        {(recoversCountMessage === 0 && cancelledLength > 0) && getStateMessage('cancelled')}
        {(recoversCountMessage === 0 && notNewTracks) && getStateMessage('refresh')}
      </StyledDivContStates>
      <StyledTooltipUpt title="Actualizar">
        <StyledBoxUptButton>
          <StyledCachedIcon />
        </StyledBoxUptButton>
      </StyledTooltipUpt>
    </StyledNewsMainCont>
  );
});

const SearchAppBar = ({
  visibleStateRows,
  dateFilter,
  filterRoutes,
  rutas,
  newTracksList,
  searchBarTextDisplay,
  secondary,
  handleSearch,
  handleLogOut,
  handleStateRow,
  handleFilterRoutes,
  handleDateFilter,
  handleUpdateTracks,
  recoversCountMessage,
  isFilterStringToday,
  location,
  handleCopy,
}) => {
  const [trackAnchorEl, setTrackAnchorEl] = useState(null);
  const [stateRowAnchorEl, setStateRowAnchorEl] = useState(null);
  const [trackFilterActive, setTrackFilterActive] = useState(false);
  const [stateRowFilterActive, setStateRowFilterActive] = useState(true);

  const buttonFilterTracksRef = useRef(null);
  const buttonFilterStateRow = useRef(null);

  const trackMenuId = 'track-menu-mobile';
  const stateRowMenuId = 'state-row-menu-mobile';

  const appBarColors = {
    backgroundColor: `${COLORS.NEUTRAL900}`,
    color: `${COLORS.SLATE50}`,
  };

  const trackIcon = (trackFilterActive)
    ? <StyledIconTrackFilterOn /> : <StyledIconTrackFilterOff />;
  const stateRowIcon = (stateRowFilterActive)
    ? <StyledIconStateRowFilterOn /> : <StyledIconStateRowFilterOff />;
  const borderTrackIcon = (trackFilterActive) ? `2px solid ${COLORS.GREEN400}` : `1px solid ${COLORS.SLATE400}`;
  const borderStateRowIcon = (stateRowFilterActive) ? `2px solid ${COLORS.GREEN400}` : `1px solid ${COLORS.SLATE400}`;

  const xsLocation = (dateFilter) ? 7 : 12;
  const mdGridSearchBar = (dateFilter) ? 5 : 8;
  const lgGridSearchBar = (dateFilter) ? 4 : 6;
  const radiusPanelSearch = (dateFilter) ? { xs: radius, md: radiusRight } : radius;
  const radiusSearchContainer = (dateFilter) ? { xs: radiusLeft, md: 0 } : radiusLeft;
  const radiusFilterTracks = (location === 'Historial') ? 0 : radiusRight;
  const radiusTextFieldLeft = (dateFilter) ? { xs: radius, md: 0 } : radius;
  const textFieldBorderColor = (!searchBarTextDisplay) ? `${COLORS.SLATE400}` : `${COLORS.GREEN500}`;
  const textFieldBorderWidth = (!searchBarTextDisplay) ? '1px' : '2px';
  const textFieldBorderLeftWidth = (!searchBarTextDisplay || dateFilter) ? '1px' : '2px';

  return (
    <>
      <DivHeader>
        <StyledAppBar position="static">
          <StyledToolbar>
            <Grid container style={{ width: '100%' }}>
              <Grid xs={xsLocation} md={4} lg={5} xl={6} sx={{ mb: { xs: 1.5, md: 0 } }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                }}
                >
                  <MainMenu
                    location={location}
                    logOut={handleLogOut}
                    secondary={secondary}
                  />
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                  >
                    {location}
                  </Typography>
                </Box>
              </Grid>
              {dateFilter && (
                <Grid xs={5} md={3} lg={3} xl={2} sx={{ mb: { xs: 1.5, md: 0 } }}>
                  <Box sx={{
                    ml: 0,
                    mr: 0,
                    my: 0,
                    width: '100%',
                    height: '100%',
                    '& svg': { m: 0 },
                    '& hr': { mx: 0.5 },
                  }}
                  >
                    <DateField dateFilter={dateFilter} setDateFilter={handleDateFilter} />
                  </Box>
                </Grid>
              )}
              <Grid
                xs={12}
                md={mdGridSearchBar}
                lg={lgGridSearchBar}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  mt: { xs: 0, sm: 1, md: 0 },
                  mb: { xs: 0, sm: 2, md: 0 },
                }}
              >
                <Box sx={{
                  flexGrow: 1,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                  borderRadius: radiusPanelSearch,
                  '& svg': { m: 0.5 },
                  '& hr': { mx: 0.5 },
                }}
                >
                  <StyledSearchInput sx={{ borderRadius: radiusSearchContainer }}>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledTextField
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        inputProps: {
                          'aria-label': 'search',
                          style: { ...textFieldInputDOMStyles },
                        },
                        sx: {
                          ...textFieldInputStyles,
                          borderTopLeftRadius: radiusTextFieldLeft,
                          borderBottomLeftRadius: radiusTextFieldLeft,
                          borderColor: `${textFieldBorderColor}`,
                          borderTopWidth: textFieldBorderWidth,
                          borderBottomWidth: textFieldBorderWidth,
                          borderLeftWidth: textFieldBorderLeftWidth,
                        },
                      }}
                      placeholder="Buscar…"
                      value={searchBarTextDisplay}
                      onChange={(e) => handleSearch(e.target.value)}
                      margin="none"
                      sx={{
                        '& .Mui-focused': {
                          '&.MuiInputBase-root': {
                            borderLeftWidth: textFieldBorderLeftWidth,
                          },
                        },
                      }}
                    />
                  </StyledSearchInput>
                  <IconButton
                    size="small"
                    ref={buttonFilterTracksRef}
                    aria-label="filter"
                    aria-controls={trackMenuId}
                    aria-haspopup="true"
                    onClick={() => setTrackAnchorEl(buttonFilterTracksRef.current)}
                    color="inherit"
                    sx={{
                      height: 48,
                      border: borderTrackIcon,
                      borderRadius: radiusFilterTracks,
                    }}
                  >
                    {trackIcon}
                  </IconButton>
                  {location === 'Historial' && (
                    <IconButton
                      size="small"
                      ref={buttonFilterStateRow}
                      aria-label="filter"
                      aria-controls={stateRowMenuId}
                      aria-haspopup="true"
                      onClick={() => setStateRowAnchorEl(buttonFilterStateRow.current)}
                      color="inherit"
                      sx={{
                        height: 48,
                        border: borderStateRowIcon,
                        borderRadius: radiusRight,
                        borderLeftWidth: '1px !important',
                      }}
                    >
                      {stateRowIcon}
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </StyledToolbar>
        </StyledAppBar>
        <FilterMenu
          anchorEl={trackAnchorEl}
          setAnchorElNull={() => setTrackAnchorEl(null)}
          menuType="tracksFilter"
          menuId={trackMenuId}
          mainArray={rutas}
          filterArray={filterRoutes}
          setFilterActive={(newState) => setTrackFilterActive(newState)}
          applyFilter={handleFilterRoutes}
          appBarColors={appBarColors}
          handleCopy={handleCopy}
          location={location}
        />
        {(location === 'Historial') && (
          <FilterMenu
            anchorEl={stateRowAnchorEl}
            setAnchorElNull={() => setStateRowAnchorEl(null)}
            menuType="stateRowFilter"
            menuId={stateRowMenuId}
            mainArray={[]}
            filterArray={visibleStateRows}
            setFilterActive={(newState) => setStateRowFilterActive(newState)}
            applyFilter={handleStateRow}
            appBarColors={appBarColors}
          />
        )}
      </DivHeader>
      {(location === 'Historial' && isFilterStringToday) && (
        <StyledNewsGrid
          newsitemscount={getNewsItemCount(newTracksList)}
          onClick={handleUpdateTracks}
        >
          <NewsBar
            newTracksList={newTracksList}
            recoversCountMessage={recoversCountMessage}
          />
        </StyledNewsGrid>
      )}
    </>
  );
};

export default SearchAppBar;
