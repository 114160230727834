/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box } from '@mui/system';
import RoadOnIcon from '@mui/icons-material/EditRoadOutlined';
import Alert from './Components/Alert';
import SECRETS from './secrets';
import COLORS from './Components/styles/Colors';
import TopBar from './Components/TopBar';
import DropdownFilter from './Components/DropdownFilter';
import DatePicker from './Components/DatePicker';

const tableContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  marginRight: '25px',
};

const columnContainerStyle = {
  margin: '5px 0px',
  padding: '0 25px',
};

const thStyle = {
  textAlign: 'right',
  padding: '5px',
  backgroundColor: 'rgb(51, 65, 85)',
  color: 'rgb(248, 250, 252)',
  minWidth: '64px',
};

const tdStyle = {
  textAlign: 'right',
  padding: '2px',
  borderBottom: '1px solid rgba(224, 224, 244, 1)',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '0.875rem',
  lineHeight: '1.43',
  letterSpacing: '0.01071em',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
};

const buildRequestOptions = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
  baseURL: SECRETS.SERVERURL,
});

const getRoutes = async (token, setIsLogged) => {
  let loginError = false;
  const response = await axios.get('/controlruta', buildRequestOptions(token)).catch((error) => {
    if (error.response.status === 401) loginError = true;
  });
  if (!loginError) return response.data;
  setIsLogged(false);
  return false;
};

// modificar para obtener todos los puntos de ruta de la organizacion en una sola request
const getPointByRoute = async (token, routeId) => {
  const response = await axios.get(`/controlpunto/byRuta/${routeId}`, buildRequestOptions(token));
  return response.data;
};

const getModels = async (token, setIsLogged) => {
  const rawRoutes = await getRoutes(token, setIsLogged);

  const routesCompose = rawRoutes.map(async (route) => {
    const rawRoutePoints = await getPointByRoute(token, route.id);

    const points = rawRoutePoints
      .sort((a, b) => a.tiempo - b.tiempo)
      .slice(0, rawRoutePoints.length - 1)
      .map((point) => ({
        id: String(point.id),
        time: point.tiempo,
        label: `${point.nombre}`,
      }));

    return ({
      id: route.id,
      label: `${route.nombre} [${route.idConjunto}]`,
      idConjunto: route.idConjunto,
      points,
    });
  });

  const routes = await Promise.all(routesCompose);
  return routes;
};

const getRouteLog = async (token, date) => {
  const fecha = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  const response = await axios.post('/controlrecorrido', { fecha }, buildRequestOptions(token));
  return response.data;
};

const getUnits = async (token) => {
  const response = await axios.get('/unidades/organizacion', buildRequestOptions(token));
  return response.data;
};

const indexUnits = (units) => units.reduce((acc, unit) => ({ ...acc, [unit.idUnidad]: unit }), {});

function getDiff({ arrival, start, time }) {
  const startTime = new Date(start).getTime();
  const arrivalTime = new Date(arrival).getTime();

  const diff = (arrivalTime - startTime) / (1000 * 60);

  const delay = Math.floor((diff - time));
  return { delay, diff: Math.floor(diff) };
}
function convertToShortLocalTime(isoString) {
  const date = new Date(isoString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}
function formatDateWithoutYear(isoString) {
  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, '0');
  const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  const monthName = monthNames[date.getMonth()];
  return `${day}-${monthName}`;
}

const getData = async (token, date, model) => {
  if (date === undefined) return undefined;
  if (model === undefined) return undefined;
  const routeLog = await getRouteLog(token, date);
  const units = await getUnits(token);
  const indexedUnits = indexUnits(units);
  const puntosDictRecorridos = {};
  let counter = 0;
  routeLog.forEach((log) => {
    const {
      nombreRuta,
      idUnidad,
      nombreOperador,
      inicio,
      fin,
      terminado,
      cancelado,
      puntos,
      idControlRuta,
    } = log;

    if (terminado && !cancelado) {
      const unit = indexedUnits[idUnidad].unidad;

      const base = {
        route: nombreRuta,
        operator: nombreOperador,
        unit,
        start: convertToShortLocalTime(inicio),
        end: convertToShortLocalTime(fin),
        date: formatDateWithoutYear(inicio),
      };

      const ruta = model.find((m) => `${m.id}` === `${idControlRuta}`);
      ruta.points.forEach((pointModel) => {
        const point = puntos.find((p) => `${p.idControlPunto}` === `${pointModel.id}`);
        if (point !== undefined) {
          const { time } = pointModel;
          const { delay, diff } = getDiff({
            arrival: point.fecha,
            start: inicio,
            time,
          });
          if (typeof puntosDictRecorridos[`${point.nombrePunto} [${ruta.idConjunto}]`] === 'undefined') {
            puntosDictRecorridos[`${point.nombrePunto} [${ruta.idConjunto}]`] = [];
          }
          puntosDictRecorridos[`${point.nombrePunto} [${ruta.idConjunto}]`].push({
            ...base,
            id: point.id,
            point: point.nombrePunto,
            arrival: convertToShortLocalTime(point.fecha),
            time,
            delay,
            diff,
            descriptor: {
              routeId: idControlRuta,
              pointId: point.idControlPunto,
              delayed: delay > 0,
            },
          });
        } else if (pointModel.time === 0) {
          if (typeof puntosDictRecorridos[`${pointModel.label} [${ruta.idConjunto}]`] === 'undefined') {
            puntosDictRecorridos[`${pointModel.label} [${ruta.idConjunto}]`] = [];
          }
          puntosDictRecorridos[`${pointModel.label} [${ruta.idConjunto}]`].push({
            ...base,
            id: counter,
            point: pointModel.label,
            arrival: base.start,
            time: 0,
            delay: 0,
            diff: 0,
            descriptor: {
              routeId: idControlRuta,
              pointId: pointModel.id,
              delayed: false,
            },
          });
          counter += 1;
        }
      });
    }
  });
  Object.keys(puntosDictRecorridos)
    .forEach((index) => {
      puntosDictRecorridos[index] = puntosDictRecorridos[index].sort((a, b) => {
        if (a.time === 0) {
          const dateA = new Date(`2024-04-04T${a.start}:00Z`);
          const dateB = new Date(`2024-04-04T${b.start}:00Z`);
          return dateA - dateB;
        }
        const dateA = new Date(`2024-04-04T${a.arrival}:00Z`);
        const dateB = new Date(`2024-04-04T${b.arrival}:00Z`);
        return dateA - dateB;
      });
    });
  return puntosDictRecorridos;
};

const Filters = ({
  models,
  data,
  setFilter,
  onAlert,
}) => {
  const filterRef = useRef({});
  const [block, setBlock] = useState(true);

  const filterData = (filterD) => {
    if (data === undefined) return;
    onAlert({ message: 'Filtrado', type: 'info' });
    if (typeof filterD !== 'undefined') {
      setFilter(filterD);
      filterRef.current = filterD;
    }
  };

  const handleChangeFilter = (newFilter) => {
    filterData(newFilter);
  };

  useEffect(() => {
    filterData();
    if (data === undefined) { setBlock(true); } else { setBlock(false); }
  }, [data]);

  return (
    <Box sx={{
      display: 'inline-flex',
      padding: '1px',
      borderRadius: '0.3rem',
      backgroundColor: COLORS.GREEN400,
      '& *': {
        color: COLORS.SLATE300,
      },
      '& > button:first-of-type': {
        borderRadius: '0.3rem 0 0 0.3rem',
      },
      '& > button:last-child': {
        borderRadius: '0 0.3rem 0.3rem 0',
      },
    }}
    >
      <DropdownFilter
        state={filterRef.current}
        setState={handleChangeFilter}
        iconOpen={<RoadOnIcon />}
        checkbox={block ? undefined : { name: '', options: models }}
      />
    </Box>
  );
};

const CheckedScreen = ({
  token,
  logOut,
  checkSession,
  secondary,
}) => {
  const [isLogged, setIsLogged] = useState(true);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [alertType, setAlertType] = useState('');

  const [model, setModel] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [filteredData, setFilteredData] = useState(undefined);
  const [date, setDate] = useState(new Date());
  const [filter, setFilter] = useState({ all: true });

  const navigate = useNavigate();

  const createAlert = ({ message, type }) => {
    setMsg(message);
    setAlertType(type);
    setOpen(true);
  };

  const createErrorAlert = (err) => {
    console.error(err);
    createAlert({ type: 'error', message: 'Algo salió mal' });
  };

  useEffect(() => { if (!checkSession()) setIsLogged(false); });

  useEffect(() => {
    if (secondary === 'false') return;
    navigate('/');
  }, [secondary]);

  useEffect(() => {
    if (!isLogged) {
      logOut();
      navigate('/');
    }
  }, [isLogged]);

  useEffect(() => {
    const initFetch = async () => {
      const MODELS = await getModels(token, setIsLogged);
      setModel(MODELS);
      const DATA = await getData(token, date, MODELS);
      setData(DATA);

      return { models: MODELS, data: DATA };
    };

    initFetch().catch(createErrorAlert);
  }, []);

  useEffect(() => {
    const initFetch = async () => {
      const DATA = await getData(token, date, model);
      setData(DATA);
    };

    initFetch().catch(createErrorAlert);
  }, [date]);

  useEffect(() => {
    const cdata = { ...data };
    const outputFilteredData = {};
    Object.keys(cdata)
      .forEach((key) => {
        const filteredArray = cdata[key]
          .filter((r) => Object.keys(filter)
            .filter((k) => filter[k]).includes(`${r.descriptor.routeId}`) || filter.all);
        if (filteredArray.length > 0) {
          outputFilteredData[key] = filteredArray;
        }
      });
    setFilteredData(outputFilteredData);
  }, [filter, data]);

  const Tools = (
    <>
      <DatePicker label="Fecha de consulta" value={date} onChange={setDate} />
      <Filters
        models={model}
        data={data}
        setFilter={setFilter}
        token={token}
        onAlert={createAlert}
      />
    </>
  );

  return (
    <>
      <Alert
        {...{
          msg,
          alertType,
          open,
          setOpen,
        }}
      />
      <TopBar
        secondary={secondary}
        tools={Tools}
        handleLogOut={logOut}
        location="Frecuencias"
      >
        <div style={tableContainerStyle}>
          {filteredData && Object.keys(filteredData)
            .sort((a, b) => (
              new Date(`2024-04-04T${filteredData[a][0].arrival}Z`) - new Date(`2024-04-04T${filteredData[b][0].arrival}Z`)))
            .map((pointId) => (
              <div key={pointId} style={columnContainerStyle}>
                <h4 style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.75)' }}>
                  {pointId}
                </h4>
                <table style={{ borderSpacing: '0' }}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Unidad</th>
                      <th style={thStyle}>Hora</th>
                      <th style={thStyle}>Dif</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData[pointId].map((punto, index) => {
                      if (punto.time === 0) {
                        return (
                          <tr key={punto.id}>
                            <td style={tdStyle}>{punto.unit}</td>
                            <td style={tdStyle}>{punto.start}</td>
                            <td style={tdStyle}>{index - 1 >= 0 ? `${(new Date(`2024-04-04T${punto.start}:00Z`) - new Date(`2024-04-04T${filteredData[pointId][index - 1].start}:00Z`)) / 1000 / 60}min` : '--'}</td>
                          </tr>
                        );
                      }
                      return (
                        <tr key={punto.id}>
                          <td style={tdStyle}>{punto.unit}</td>
                          <td style={tdStyle}>{punto.arrival}</td>
                          <td style={tdStyle}>{index - 1 >= 0 ? `${(new Date(`2024-04-04T${punto.arrival}:00Z`) - new Date(`2024-04-04T${filteredData[pointId][index - 1].arrival}:00Z`)) / 1000 / 60}min` : '--'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ))}
        </div>
      </TopBar>
    </>
  );
};

export default CheckedScreen;
