import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider, FormControlLabel, IconButton, List, ListItem, Menu, MenuItem, Radio, RadioGroup,
} from '@mui/material';
import { Box } from '@mui/system';
import { GridFilterListIcon } from '@mui/x-data-grid';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import COLORS from './styles/Colors';

const styles = {
  input: {
    color: COLORS.NEUTRAL300,
    '&.Mui-checked': {
      color: COLORS.GREEN400,
    },
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.5)',
    borderBottomWidth: 'inherit',
  },
  list: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto',
    '&::-webkit-scrollbar': { width: '8px' },
    '&::-webkit-scrollbar-track': { backgroundColor: '#939393', borderRadius: '8px' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: COLORS.GREEN400, borderRadius: '8px' },
  },
  menuCheckbox: {
    maxHeight: '80vh',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'max-content max-content 1fr max-content max-content',
  },
  button: {
    borderColor: `${COLORS.GREEN400}`,
    borderWidth: '2px',
    color: `${COLORS.GREEN400}`,
    transition: 'all 0.25s ease-out',
    fontWeight: 'bold',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: `${COLORS.NEUTRAL700}`,
      borderColor: `${COLORS.GREEN300}`,
      color: `${COLORS.GREEN300}`,
      transition: 'all 0.25s ease-in',
    },
  },
};

const CustomDivider = () => <Divider light sx={{ '&.MuiDivider-root': { ...styles.divider } }} variant="middle" />;
const CustomButtonSubmit = () => (
  <Button
    variant="outlined"
    type="submit"
    endIcon={(<GridFilterListIcon />)}
    fullWidth
    color="inherit"
    onClick={() => {}}
    sx={styles.button}
  >
    Aplicar
  </Button>
);

const CheckedWrapper = ({
  onSubmit, options, state, name,
}) => {
  const [localState, setLocalState] = useState(state);

  const handleChange = (event) => {
    const { target: { dataset, checked } } = event;
    const { name: n, id } = dataset;

    if (id === 'all') {
      const newState = options.reduce((acc, cur) => ({ ...acc, [`${n}${cur.id}`]: checked }), {});
      setLocalState((prev) => ({ ...prev, ...newState, [`${n}all`]: checked }));
      return;
    }

    setLocalState((prev) => ({ ...prev, [`${n}${id}`]: checked }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(localState);
  };

  return (
    <Box component="form" onChange={handleChange} onSubmit={handleSubmit} sx={{ ...styles.menuCheckbox }}>
      <MenuItem>
        <FormControlLabel
          control={(
            <Checkbox
              sx={styles.input}
              checked={localState[`${name}all`] ?? false}
              inputProps={{ 'data-id': 'all', 'data-name': name }}
            />
          )}
          label="Todos"
        />
      </MenuItem>
      <CustomDivider />
      <List sx={{ ...styles.list }}>
        {options !== undefined && options.map((row) => (
          <ListItem key={row.id}>
            <FormControlLabel
              control={(
                <Checkbox
                  sx={styles.input}
                  checked={localState[`${name}${row.id}`] ?? false}
                  inputProps={{ 'data-id': row.id, 'data-name': name }}
                />
            )}
              label={row.label}
            />
          </ListItem>
        ))}
      </List>
      <CustomDivider />
      <Box component="nav" sx={{ padding: '0 16px' }}>
        <CustomButtonSubmit />
      </Box>
    </Box>
  );
};

const RadioWrapper = ({
  onSubmit, options, state, name, defaultValue,
}) => {
  const [localState, setLocalState] = useState(state);

  const handleChange = (event) => {
    const { target: { name: n, value } } = event;

    setLocalState((prev) => ({ ...prev, [n]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(localState);
  };

  return (
    <Box component="form" onChange={handleChange} onSubmit={handleSubmit}>
      <Box sx={styles.list}>
        <RadioGroup name={name} defaultValue={defaultValue} value={localState[name] ?? defaultValue ?? ''}>
          {options !== undefined && options.map((row) => (
            <MenuItem key={row.id}>
              <FormControlLabel
                control={<Radio sx={styles.input} />}
                value={row.value}
                label={row.label}
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </Box>
      <CustomDivider />
      <Box component="nav" sx={{ padding: '0 16px ' }}>
        <CustomButtonSubmit />
      </Box>
    </Box>
  );
};

const DropdownFilter = ({
  iconOpen, radio, checkbox, state, setState,
}) => {
  const [active, setActive] = useState([radio, checkbox]
    .some((e) => (e !== undefined) && (e.options !== undefined)));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const flag = useRef(false);

  const handleClick = (event) => {
    if (!active) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (d) => {
    setState(d);
  };

  const setInitialValues = () => {
    console.log('setInitialValues');
    if (checkbox === undefined || checkbox === null) return;

    const hasAll = Object.hasOwnProperty.call(state, `${checkbox.name}all`);

    if (hasAll) return;

    const initialValue = true;

    const newState = checkbox.options.reduce((acc, cur) => ({ ...acc, [`${checkbox.name}${cur.id}`]: initialValue }), {});

    setState(({ ...state, ...newState, [`${checkbox.name}all`]: initialValue }));
  };

  useEffect(() => {
    const hasContent = [radio, checkbox].some((e) => e !== undefined);
    setActive(hasContent);

    if (hasContent && flag.current === true) {
      setInitialValues();
      flag.current = false;
    }

    if (hasContent === false) {
      flag.current = true;
    }
  }, [radio, checkbox]);

  const hasCheckbox = checkbox && (
    <CheckedWrapper
      onSubmit={handleSubmit}
      options={checkbox.options}
      name={checkbox.name}
      state={state}
    />
  );

  const hasRadio = radio && (
    <RadioWrapper
      onSubmit={handleSubmit}
      options={radio.options}
      name={radio.name}
      state={state}
      defaultValue={radio.defaultValue}
    />
  );

  const Popup = (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: 350,
          backgroundColor: COLORS.NEUTRAL800,
          color: COLORS.SLATE50,
        },
      }}
    >
      {hasRadio}
      {hasCheckbox}
    </Menu>
  );

  return (
    <>
      <IconButton
        size="small"
        ref={null}
        aria-label="filter"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={!active}
        color="inherit"
        sx={{
          height: 48,
          width: 48,
          border: `1px solid ${COLORS.GREEN400}`,
          borderRadius: '0',
          backgroundColor: `${COLORS.NEUTRAL900}`,
          '&:hover *': {
            color: `${COLORS.NEUTRAL900}`,
          },
          '&:disabled': {
            backgroundColor: `${COLORS.NEUTRAL800}`,
            '& *': {
              opacity: '0.5',
            },
          },
        }}
      >
        { active ? iconOpen : <AutorenewIcon /> }
      </IconButton>
      {open && Popup}
    </>
  );
};

export default DropdownFilter;
