import React, { useState, useEffect } from 'react';
import {
  Menu,
  ListItemButton,
  ListItemText,
  ListItem,
  Checkbox,
  Divider,
  Button,
} from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterAlt';
import CopyIcon from '@mui/icons-material/ContentCopy';
import COLORS from './styles/Colors';

const dividerStyle = {
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'rgba(255, 255, 255, 0.5)',
  borderBottomWidth: 'inherit',
  marginTop: '0.25rem',
};

const stateRowAllArray = [0, 1, 2];

const addRemoveToArray = (array, currentValue, isRemove) => {
  const index = array.indexOf(currentValue);
  const arrayToReturn = [...array];

  if (index === -1) {
    arrayToReturn.push(currentValue);
  } else if (isRemove) {
    arrayToReturn.splice(index, 1);
  }
  return arrayToReturn;
};

const PopoverMenu = ({
  anchorEl,
  setAnchorElNull,
  menuType,
  menuId,
  mainArray,
  filterArray,
  setFilterActive,
  applyFilter,
  appBarColors,
  location,
  handleCopy,
}) => {
  const [stateBeforeFilter, setStateBeforeFilter] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!firstLoad) return;
    if (!anchorEl) return;
    if (stateBeforeFilter.length > 0) return;

    const data = filterArray.length > 0
      ? filterArray
      : mainArray.map((item) => (item.id));

    setStateBeforeFilter(data);
    setFirstLoad(false);
  }, [mainArray, anchorEl]);

  const isMobileOpen = Boolean(anchorEl);

  const mainValuesObj = {
    tracksFilter: {
      selectAll: {
        key: 'ruta-all',
        selectAllValue: 0,
        checkedAll: stateBeforeFilter.length === mainArray.length,
        ariaLabel: 'checkbox-list-routes-all-selected',
        allSelectText: 'Seleccionar todas',
      },
      arrayLength: mainArray.length,
      conditional: (element) => !element,
      menuValues: {
        widthProp: {},
        menuArray: mainArray,
      },
    },
    stateRowFilter: {
      selectAll: {
        key: 'state-row-all',
        selectAllValue: (-1),
        checkedAll: stateBeforeFilter.length === 3,
        ariaLabel: 'checkbox-list-state-row-all-selected',
        allSelectText: 'Seleccionar todos',
      },
      arrayLength: stateRowAllArray.length,
      conditional: (element) => !(element >= 0),
      menuValues: {
        widthProp: { minWidth: '13rem' },
        menuArray: ['cancelado', 'activo', 'terminado'],
      },
    },
  };
  const mainValues = mainValuesObj[menuType];
  const {
    selectAll: {
      key,
      selectAllValue,
      checkedAll,
      ariaLabel,
      allSelectText,
    },
    arrayLength,
    conditional,
    menuValues: {
      widthProp,
      menuArray,
    },
  } = mainValues;

  const handleToggle = (valueItem, isAllSelectClicked) => () => {
    const arrayValuesObj = {
      tracksFilter: mainArray.map((item) => (item.id)),
      stateRowFilter: stateRowAllArray,
    };
    const arrayValues = arrayValuesObj[menuType];
    const newFilter = addRemoveToArray(stateBeforeFilter, valueItem, true);
    const index = newFilter.findIndex(conditional);
    if (typeof (index) === 'number' && index > -1) newFilter.splice(index, 1);
    if (isAllSelectClicked) {
      const newSettedArray = (newFilter.length === arrayLength) ? [] : arrayValues;
      setStateBeforeFilter(newSettedArray);
      return;
    }
    setStateBeforeFilter(newFilter);
  };

  const handleApply = () => {
    const newFilter = [...stateBeforeFilter];
    const index = newFilter.findIndex(conditional);
    if (typeof (index) === 'number' && index > -1) {
      newFilter.splice(index, 1);
    }
    setFilterActive(newFilter.length !== arrayLength);
    applyFilter(newFilter);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      anchorPosition={{ left: 0, top: 0 }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileOpen}
      onClose={setAnchorElNull}
      sx={{ p: 0, '& .MuiMenu-paper': { ...appBarColors, backgroundColor: `${COLORS.NEUTRAL800}`, ...widthProp } }}
    >
      <ListItem
        key={key}
        disablePadding
        secondaryAction={(
          <Checkbox
            edge="end"
            color="default"
            onChange={handleToggle(selectAllValue, true)}
            checked={checkedAll}
            inputProps={{ 'aria-labelledby': ariaLabel }}
            sx={{
              color: `${COLORS.NEUTRAL300}`,
              '&.Mui-checked': {
                color: `${COLORS.GREEN400}`,
              },
            }}
          />
        )}
      >
        <ListItemButton sx={{ py: 0.25 }}>
          <ListItemText id={ariaLabel} primary={checkedAll ? 'Quitar selección' : allSelectText} />
        </ListItemButton>
      </ListItem>
      <Divider light sx={{ '&.MuiDivider-root': { ...dividerStyle, marginBottom: '0.5rem' } }} variant="middle" />
      {menuArray?.map((item, index) => {
        const itemPropsObj = {
          tracksFilter: {
            idElement: item.id,
            nameElement: item.nombre,
            keyElement: 'route',
            labelId: 'checkbox-list-secondary-label',
            isChecked: stateBeforeFilter.includes(item.id),
            itemMethod: handleToggle(item.id, false),
            capitalizeItem: {},
          },
          stateRowFilter: {
            idElement: index,
            nameElement: item,
            keyElement: 'state-row',
            labelId: 'checklist-state-row',
            isChecked: stateBeforeFilter.includes(index),
            itemMethod: handleToggle(index, false),
            capitalizeItem: { textTransform: 'capitalize' },
          },
        };
        const itemProps = itemPropsObj[menuType];
        const {
          idElement,
          nameElement,
          keyElement,
          labelId,
          isChecked,
          itemMethod,
          capitalizeItem,
        } = itemProps;
        return (
          <ListItem
            key={`${keyElement}-${idElement}`}
            secondaryAction={(
              <Checkbox
                edge="end"
                color="default"
                onChange={itemMethod}
                checked={isChecked}
                inputProps={{ 'aria-labelledby': `${labelId}-${idElement}` }}
                sx={{
                  color: `${COLORS.NEUTRAL300}`,
                  '&.Mui-checked': {
                    color: `${COLORS.GREEN400}`,
                  },
                }}
              />
          )}
            disablePadding
          >
            <ListItemButton sx={{ py: 0.25 }}>
              <ListItemText id={`${labelId}-${idElement}`} sx={{ ...capitalizeItem }} primary={nameElement} />
            </ListItemButton>
          </ListItem>
        );
      })}
      <Divider light sx={{ '&.MuiDivider-root': { ...dividerStyle } }} variant="middle" />
      <ListItem
        key={`${menuType}-apply-filters`}
        disablePadding
        sx={{ width: '100%', px: 2, mt: 1.5 }}
      >
        <Button
          variant="outlined"
          endIcon={(<FilterIcon />)}
          fullWidth
          color="inherit"
          onClick={handleApply}
          sx={{
            borderColor: `${COLORS.GREEN400}`,
            borderWidth: '2px',
            color: `${COLORS.GREEN400}`,
            transition: 'all 0.25s ease-out',
            fontWeight: 'bold',
            lineHeight: '1',
            '&:hover': {
              backgroundColor: `${COLORS.NEUTRAL700}`,
              borderColor: `${COLORS.GREEN300}`,
              color: `${COLORS.GREEN300}`,
              transition: 'all 0.25s ease-in',
            },
          }}
        >
          Aplicar
        </Button>
        {location === 'En curso' && (
          <Button
            variant="outlined"
            endIcon={(<CopyIcon />)}
            fullWidth
            color="inherit"
            onClick={() => handleCopy(stateBeforeFilter)}
            sx={{
              borderColor: `${COLORS.GREEN400}`,
              borderWidth: '2px',
              color: `${COLORS.GREEN400}`,
              transition: 'all 0.25s ease-out',
              fontWeight: 'bold',
              width: 'auto',
              minWidth: '5px',
              marginLeft: '5px',
              '>span': {
                margin: 0,
              },
              '&:hover': {
                backgroundColor: `${COLORS.NEUTRAL700}`,
                borderColor: `${COLORS.GREEN300}`,
                color: `${COLORS.GREEN300}`,
                transition: 'all 0.25s ease-in',
              },
            }}
          />
        )}
      </ListItem>
    </Menu>
  );
};

export default PopoverMenu;
