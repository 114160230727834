import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '@fontsource/roboto-mono/500.css';
import COLORS from './styles/Colors';
import './styles/LoaderStyles.css';
import TrackPointsRow from './TrackPointsRow';

const fontRobotoMono = { fontFamily: '"Roboto Mono", monospace' };

const StyledTableContainer = styled(TableContainer)(() => ({
  position: 'relative',
  borderRadius: 0,
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
}));

const StyledBoxLoader = styled(Box)(() => ({
  backgroundColor: 'white',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flex: '1 1 auto',
  height: '100%',
  width: '100%',
  zIndex: 50,
}));

const StyledTypoLoader = styled(Typography)(() => ({
  ...fontRobotoMono,
  marginTop: '0.875rem',
  fontSize: 20,
  color: `${COLORS.SLATE500}`,
}));

const StyledTable = styled(Table)(() => ({
  borderRadius: 0,
  '& .MuiTableCell-head': {
    color: COLORS.SLATE50,
    backgroundColor: COLORS.NEUTRAL700,
    borderRadius: 0,
    padding: '0.25rem 0.5rem',
  },
  '& .MuiTableCell-body': { padding: '0.25rem 0.5rem' },
}));

const StyledTablePagination = styled(TablePagination)(() => ({
  minHeight: '52px',
  boxShadow: '0px -1px 10px 6px rgb(64 64 64 / 75%)',
  backgroundColor: `${COLORS.NEUTRAL800}`,
  '& .MuiTablePagination-toolbar': { color: `${COLORS.NEUTRAL50}` },
  '& .MuiTablePagination-selectIcon': { color: `${COLORS.NEUTRAL50}` },
  '& .MuiButtonBase-root.Mui-disabled': { color: `${COLORS.NEUTRAL500}` },
}));

const BaseTableHeader = ({ typeTable }) => {
  const columnArray = [];
  const detailColWidth = (typeTable === 'mainScreen') ? '23rem' : '15rem';
  const addColumnObj = (field, headerName, width, align, isMinWidth) => {
    columnArray.push({
      field,
      headerName,
      width,
      align,
      isMinWidth,
    });
  };
  addColumnObj('track', 'Recorrido', '4rem', 'left', true);
  addColumnObj('driver', 'Conductor', '4rem', 'left', true);
  addColumnObj('created-at', 'Alta', '2rem', 'left', true);
  addColumnObj('unit', 'Unidad', '3rem', 'right', true);
  if (typeTable === 'historyScreen') {
    addColumnObj('status', 'Status', '5rem', 'left', true);
  }
  // addColumnObj('total', 'Total', '5rem', 'left', false); // Column total
  addColumnObj('delay', 'Atraso', '5rem', 'center', false);
  addColumnObj('detail', 'Detalle de recorrido', detailColWidth, 'left', false);
  return (
    <TableHead sx={{ borderRadius: 0, whiteSpace: 'nowrap' }}>
      <TableRow>
        {columnArray.map(({
          field,
          headerName,
          width,
          align,
          isMinWidth,
        }) => {
          const widthObj = (isMinWidth) ? { minWidth: width } : { width };
          return (
            <TableCell
              key={`main-table-head-${field}`}
              align={align}
              variant="head"
              padding="none"
              sx={{ ...widthObj, whiteSpace: 'unset' }}
            >
              {headerName}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const CollapsibleTable = ({
  mainDataArray,
  checadas,
  unidades,
  secondary,
  pointsRoute,
  visibleStateRows,
  page,
  rowsPerPage,
  liftingAction,
  setInfoInDialog,
  handlePageChange,
  handleChangeRowsPerPage,
  handleComputePoints,
  isNewUptList,
  recoversList,
  isLoadingTable,
  updatesTracksArray,
  computedTracks,
  typeTable,
}) => {
  const visibleData = (mainDataArray.length > 0)
    ? mainDataArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : mainDataArray;
  return (
    <>
      <StyledTableContainer component={Paper}>
        {typeTable === 'historyScreen' && isLoadingTable && (
          <StyledBoxLoader>
            <div className="loader-spinner" />
            <StyledTypoLoader>
              Cargando...
            </StyledTypoLoader>
          </StyledBoxLoader>
        )}
        {(typeTable !== 'historyScreen' || !isLoadingTable) && (
          <>
            <StyledTable stickyHeader aria-label="collapsible table">
              <BaseTableHeader typeTable={typeTable} />
              <TableBody>
                {visibleData.map((row) => (
                  <TrackPointsRow
                    key={`tpr-${row.id}`}
                    row={row}
                    secondary={secondary}
                    checadas={checadas}
                    unidades={unidades}
                    pointsRoute={pointsRoute}
                    liftingAction={liftingAction}
                    visibleStateRows={visibleStateRows}
                    handleComputePoints={handleComputePoints}
                    setInfoInDialog={setInfoInDialog}
                    isNewUptList={isNewUptList}
                    recoversList={recoversList}
                    updatesTracksArray={updatesTracksArray}
                    computedTracks={computedTracks}
                    typeTable={typeTable}
                  />
                ))}
              </TableBody>
            </StyledTable>
            {mainDataArray.filter((f) => visibleStateRows.includes(f.stateRow)).length === 0 && (
              <span>No hay registros para esta fecha.</span>
            )}
          </>
        )}
      </StyledTableContainer>
      <StyledTablePagination
        component="div"
        count={mainDataArray.length}
        rowsPerPageOptions={[10, 20, 50, 100]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          MenuProps: {
            sx: {
              '& .MuiMenu-paper': {
                backgroundColor: `${COLORS.NEUTRAL700}`,
                color: `${COLORS.NEUTRAL50}`,
                '& .MuiMenuItem-root': { transition: 'all 0.25s ease-out' },
                '& .MuiMenuItem-root:hover': {
                  backgroundColor: `${COLORS.NEUTRAL600}`,
                  transition: 'all 0.25s ease-in',
                },
                '& .MuiMenuItem-root.Mui-selected': { backgroundColor: `${COLORS.NEUTRAL500}` },
                '& .MuiMenuItem-root.Mui-selected:hover': { backgroundColor: 'rgb(135, 135, 135)' },
                '& .MuiList-root': { paddingTop: 0, paddingBottom: 0 },
              },
            },
          },
        }}
      />
    </>
  );
};

export default CollapsibleTable;
