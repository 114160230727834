export const getFormatTimeString = (string) => {
  const time = string.toTimeString().split(' ')[0] ?? '';
  if (time.length > 0) {
    const timeArray = time.split(':');
    if (timeArray.length === 3) {
      return `${timeArray[0]}:${timeArray[1]}`;
    }
  }
  return '';
};

export default {
  getFormatTimeString,
};
