import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import COLORS from '../styles/Colors';

const StyledSelect = styled(Select)(() => ({
  borderColor: `${COLORS.GREEN400}`,
  color: `${COLORS.GREEN400}`,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${COLORS.GREEN400}`,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: `${COLORS.GREEN300}`,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: `${COLORS.GREEN300}`,
  },
  '& .MuiSelect-select': {
    color: `${COLORS.GREEN400}`,
  },
}));

const DarkSelect = (props) => {
  const {
    value, onChange, options, placeholder,
  } = props;

  return (
    <StyledSelect
      value={value}
      onChange={onChange}
      variant="outlined"
      displayEmpty
      fullWidth
    >
      <MenuItem value="">
        {placeholder}
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default DarkSelect;
