/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import AppBar from './Components/AppBar';
import MainTable from './Components/CollapsibleTable';
import MainStyles from './Components/styles/GeneralStyles';
import ActionDialog from './Components/basics/ActionsDialog';
import InfoDialog from './Components/basics/InfoDialog';
import Alert from './Components/Alert';

import SECRETS from './secrets';
import {
  RUTASCACHE,
  USRCACHE,
  PUNTOSCACHE,
  RUTASIDS,
  UNITSCACHE,
} from './Config';
import { getFormatTimeString } from './util';

const { initBox } = MainStyles;

const twoDigits = (num) => {
  const numTwoDigits = (num <= 9) ? `0${num}` : `${num}`;
  return numTwoDigits;
};

const localeTimeString = (date) => getFormatTimeString(date);

const MainScreen = ({
  socket,
  token,
  logOut,
  checkSession,
  secondary,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [isSocketActive, setIsSocketActive] = useState(false);
  const [isLogged, setIsLogged] = useState(true);
  const [unidadesArray, setUnidadesArray] = useState([]);
  const [usersArray, setUsersArray] = useState([]);
  const [pointsRoute, setPointsRoute] = useState({});
  const [currentTracks, setCurrentTracks] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [searchBarTextDisplay, setSearchBarTextDisplay] = useState('');
  const [filtroRutas, setFiltroRutas] = useState([]);
  const [checadas, setChecadas] = useState({});
  const [loadingFlag, setLoadingFlag] = useState(false); // manda cargar controlrecorrido onChange
  // Alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [alertType, setAlertType] = useState('');
  // Info Dialog
  const [infoDialogState, setInfoDialogState] = useState({
    isOpen: false,
    track: '',
    driver: '',
    unit: '',
    createdAt: '',
    initDate: '',
    initTime: '',
    end: '',
    mainDiffDisplay: '',
    stateRow: 0,
    checkerName: '',
    arrayTable: [],
  });
  // Actions Dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(false);
  const [infoPanel, setInfoPanel] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [mainDataArray, setMainDataArray] = useState([]);
  const navigate = useNavigate();
  const visibleStateRows = [1];

  const setMainData = () => {
    const getStateRow = (cancel, finished) => {
      if (cancel) { return 'cancelado'; }
      if (finished) { return 'terminado'; }
      return 'activo';
    };
    const date = new Date();
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post('/controlrecorrido', {
        fecha: `${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`,
      })
      .then((tableData) => {
        const tableArray = tableData.data.map((track) => {
          const {
            nombreRuta,
            inicio,
            nombreOperador,
            cancelado,
            terminado,
            puntos,
            idUsuario,
          } = track;
          const stateRowObj = {
            cancelado: 0,
            activo: 1,
            terminado: 2,
          };
          const stateRow = stateRowObj[getStateRow(cancelado, terminado)];
          return ({
            ...track,
            puntos,
            editable: false,
            textFilter: true,
            track: nombreRuta,
            trackInit: inicio,
            driver: nombreOperador,
            lastPoint: '-',
            diff: '-',
            points: [],
            selectFilter: true,
            stateRow,
            checkerName: usersArray.find((user) => user.idUsuario === idUsuario)?.nombre ?? '',
          });
        }).sort((a, b) => {
          if (a.trackInit > b.trackInit) { return -1; }
          if (a.trackInit < b.trackInit) { return 1; }
          return 0;
        });
        return tableArray;
      }).then((data) => {
        setCurrentTracks(data);
        setMsg('Tabla cargada');
        setAlertType('success');
        setOpen(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const controlPointSocket = (data) => {
    const { imei } = data;
    // console.log(`${imei} s:${new Date(data.date).toLocaleString(
    // 'es-MX', { time: 'short' })} r:${new Date().toLocaleString('es-MX', { time: 'short' })}`);
    setChecadas((chec) => {
      const clone = { ...chec };
      if (typeof clone[imei] === 'undefined') {
        clone[imei] = {
          latest: data,
          list: [data],
        };
        return clone;
      }
      clone[imei].latest = data;
      clone[imei].list.push(data);
      return clone;
    });
  };

  const statusSocket = ({ status, recorrido }) => {
    setCurrentTracks((ct) => {
      const clone = [...ct];
      let index = -1;
      const stateRowObj = {
        cancelado: 0,
        activo: 1,
        terminado: 2,
      };
      switch (status) {
        case 'cancelado':
        case 'terminado':
          index = clone.findIndex((c) => c.id === recorrido.id);
          if (index >= 0) {
            const { imei } = clone[index];
            console.log(`recorrido terminado ${imei}`);
            clone[index] = {
              ...clone[index],
              cancelado: (status === 'cancelado'),
              terminado: (status === 'terminado'),
              stateRow: stateRowObj[status],
              // Se puede actualizar fecha/hora de fin
            };
            clone.splice(index, 1);
            setChecadas((chec) => {
              const cloneChe = { ...chec };
              if (typeof cloneChe[imei] !== 'undefined') {
                delete cloneChe[imei];
                return cloneChe;
              }
              return cloneChe;
            });
          }
          return clone;
        case 'nuevo':
          setChecadas((chec) => {
            const cloneChec = { ...chec };
            cloneChec[recorrido.imei] = {
              lastest: null,
              list: [],
            };
            return cloneChec;
          });
          return [
            {
              ...recorrido,
              editable: false,
              textFilter: true,
              track: recorrido.nombreRuta,
              trackInit: recorrido.inicio,
              driver: recorrido.nombreOperador,
              lastPoint: '-',
              diff: '-',
              points: [],
              selectFilter: true,
              stateRow: 1,
              checkerName: usersArray.find((user) => user.idUsuario === recorrido.idUsuario)?.nombre ?? '',
            },
            ...clone,
          ];
        default:
          console.log('default');
      }
      return ct;
    });
  };

  useEffect(() => { if (!checkSession()) setIsLogged(false); });

  useEffect(() => {
    async function mainLoad() {
      // load rutas, usuarios, unidades, puntos from localStorage
      try {
        setRutas(JSON.parse(localStorage.getItem(RUTASCACHE)) || []);
      } catch (error) {
        console.error('rutas cache is corrupt');
      }
      try {
        setUsersArray(JSON.parse(localStorage.getItem(USRCACHE)) || []);
      } catch (error) {
        console.error('usuarios cache is corrupt');
      }
      try {
        setPointsRoute(JSON.parse(localStorage.getItem(PUNTOSCACHE)) || []);
      } catch (error) {
        console.error('puntos cache is corrupt');
      }
      try {
        const ids = JSON.parse(localStorage.getItem(RUTASIDS)) || [];
        setFiltroRutas(ids);
      } catch (error) {
        console.error('rutas ids cache is corrupt');
      }
      try {
        setUnidadesArray(JSON.parse(localStorage.getItem(UNITSCACHE)) || []);
      } catch (error) {
        console.error('unidades cache is corrupt');
      }
      if (!loadingFlag) {
        setLoadingFlag(true);
      }
      try {
        let loginError = false;
        let orgIdError = false;
        axios.defaults.headers.common.Authorization = `bearer ${token}`;
        axios.defaults.baseURL = SECRETS.SERVERURL;
        const response = await axios.get('/controlruta').catch((error) => {
          if (error.response.status === 401) loginError = true;
        });
        const unitsOrgFetch = await axios.get('/unidades/organizacion').catch((error) => {
          if (error.response.status === 401) orgIdError = true;
        });
        if (!loginError && !orgIdError) {
          setRutas(response.data);
          localStorage.setItem(RUTASCACHE, JSON.stringify(response.data));
          const ObjToPush = {};
          const arrayIds = response.data.map(({ id }) => id);
          const { data: pointsArray } = await axios.post('/controlpunto/all', { ids: arrayIds })
            .catch((e) => console.error(e));
          response.data.forEach(({ id }) => {
            ObjToPush[id] = pointsArray.filter((point) => point.idControlRuta === id);
          });
          setPointsRoute(ObjToPush);
          localStorage.setItem(PUNTOSCACHE, JSON.stringify(ObjToPush));
          setFiltroRutas(arrayIds);
          localStorage.setItem(RUTASIDS, JSON.stringify(arrayIds));
          const unitsOrgData = unitsOrgFetch.data;
          const sortedUnits = unitsOrgData.sort((a, b) => a.unidad.localeCompare(b.unidad));
          setUnidadesArray(sortedUnits);
          localStorage.setItem(UNITSCACHE, JSON.stringify(sortedUnits));
          const usersOrgFetch = await axios.get('/usuarios/organizacion');
          const usersOrgData = usersOrgFetch.data;
          setUsersArray(usersOrgData);
          localStorage.setItem(USRCACHE, JSON.stringify(usersOrgData));
          if (!loadingFlag) {
            setLoadingFlag(true);
          }
        } else {
          setIsLogged(false);
        }
      } catch (reason) {
        console.error(`mainLoad/promisesArray error:: ${reason}`);
      }
    }
    mainLoad();

    // socket effects
    const loadDataSuccess = () => console.log(`Socket connected: ${socket.id}`);
    let socketIdAssign = socket?.id;
    const socketDisconnect = (reason) => {
      // eslint-disable-next-line no-console
      console.log(`socket disconnect:: ${reason}`);
      setIsSocketActive(false);
      setMsg('Conexión fallida');
      setAlertType('warning');
      setOpen(true);
    };
    const socketConnect = () => {
      setIsSocketActive(true);
      socketIdAssign = socket.id;
      socket.emit('login', token);
      loadDataSuccess();
    };
    socket.on('connect', socketConnect);
    socket.on('disconnect', socketDisconnect);
    socket.on('PuntoControl', controlPointSocket);
    socket.on('status', statusSocket);

    if (!socketIdAssign) socket.connect();
    else loadDataSuccess();

    return (() => {
      socket.off('connect', socketConnect);
      socket.off('disconnect', socketDisconnect);
      socket.off('PuntoControl', controlPointSocket);
      socket.off('status', statusSocket);
    });
  }, []);

  useEffect(() => {
    if (!isLogged) {
      navigate('/');
      logOut();
    }
  }, [isLogged]);

  useEffect(() => {
    if (usersArray.length > 0) {
      setMainData();
    }
  }, [loadingFlag]);

  // socket effect
  // useEffect(() => {
  // }, [checadas, currentTracks]);

  const searchFilter = (row, searchValue, unidades) => {
    const removeTilde = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const stringToSearch = searchValue.toLowerCase() || '';
    const matcher = new RegExp(`.*${removeTilde(stringToSearch)}.*`);
    const unidad = unidades.find((u) => u.idUnidad === row.idUnidad);
    if (typeof unidad?.unidad !== 'undefined') {
      return (
        matcher.test(removeTilde(row.nombreRuta.toLowerCase()))
        || matcher.test(removeTilde(unidad.unidad.toLowerCase()))
        || matcher.test(removeTilde(row.nombreOperador.toLowerCase()))
        || matcher.test(removeTilde(row.checkerName.toLowerCase()))
      );
    }
    return (
      matcher.test(removeTilde(row.nombreRuta.toLowerCase()))
      || matcher.test(removeTilde(row.nombreOperador.toLowerCase()))
      || matcher.test(removeTilde(row.checkerName.toLowerCase()))
    );
  };

  const filterData = () => {
    const dataBeforeDebounce = currentTracks
      .filter((row) => filtroRutas.includes(row.idControlRuta))
      .filter((f) => visibleStateRows.includes(f.stateRow))
      .sort((a, b) => new Date(b.inicio) - new Date(a.inicio));
    setMainDataArray(dataBeforeDebounce
      .filter((row) => searchFilter(row, filterString, unidadesArray)));
    setPage(0);
  };

  useEffect(() => { filterData(); }, [currentTracks]);
  useEffect(() => { filterData(); }, [filterString]);
  useEffect(() => { filterData(); }, [filtroRutas]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const debouncedFilter = useCallback(debounce((query) => {
    setFilterString(query);
  }, 500), []);

  const debouncedFilterString = (query) => {
    setSearchBarTextDisplay(query);
    debouncedFilter(query);
  };

  const liftingAction = (recorridoId, action) => {
    const {
      id: trackId,
      track,
      trackInit,
      imei,
      driver,
    } = currentTracks.find((item) => item.id === recorridoId);
    setInfoPanel({
      trackId,
      track,
      unidad: unidadesArray.find((u) => `${u.imei}` === `${imei}`)?.unidad ?? '',
      trackInit,
      driver,
    });
    setDialogType(action);
    setIsDialogOpen(true);
  };

  const liftingActionConfirm = (recorridoId, action) => {
    setIsDialogOpen(false);
    // setLoading(true);
    const getTZUTCDate = () => {
      const now = new Date();
      const UTCYear = now.getUTCFullYear();
      const UTCMonth = twoDigits(now.getUTCMonth() + 1);
      const UTCDay = twoDigits(now.getUTCDate());
      const UTCHour = twoDigits(now.getUTCHours());
      const UTCMinutes = twoDigits(now.getUTCMinutes());
      return `${UTCYear}-${UTCMonth}-${UTCDay}T${UTCHour}:${UTCMinutes}:00Z`;
    };
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    const actionObj = {
      cancelado: { [action]: true },
      terminado: { [action]: true, fin: getTZUTCDate() },
    };
    const actionObjSelected = actionObj[action] || {};
    axios
      .patch(`/controlrecorrido/${recorridoId}`, actionObjSelected)
      .then(() => {
        const clone = [...currentTracks];
        const index = clone.findIndex((c) => c.id === recorridoId);
        if (index >= 0) {
          const stateRowObj = { cancelado: 0, terminado: 2 };
          clone[index].stateRow = stateRowObj[action];
          // setLoading(false);
          // clone.splice(index, 1);
          setCurrentTracks(clone);
          socket.emit('status', {
            code: SECRETS.SOCKETCODE,
            status: action,
            recorrido: {
              id: recorridoId,
            },
          });
          const msgDisplayObj = {
            terminado: 'Recorrido finalizado',
            cancelado: 'Recorrido cancelado',
          };
          const msgDisplay = msgDisplayObj[action] ?? 'Se han actualizado los datos';
          setMsg(msgDisplay);
          setAlertType('success');
          setOpen(true);
        }
      })
      .catch((e) => {
        // setLoading(false);
        setMsg('Sin conexión a internet');
        setAlertType('error');
        console.error(e);
        if (e.response.status === 401) {
          // setId(0);
          // setToken('');
        }
      });
  };

  const handleInfoDialog = (newState) => (
    newState !== infoDialogState.isOpen && setInfoDialogState((currentState) => ({
      ...currentState,
      isOpen: newState,
    }))
  );

  const handlePanelInfoDialog = (trackId, arrayTable, mainDiffDisplay, row) => {
    const {
      track,
      driver,
      imei,
      createdAt,
      trackInit,
      fin,
      stateRow,
      idUsuario,
      comentario,
    } = row;
    const trackInitFmtDate = new Date(trackInit);
    const checkerName = usersArray.find((user) => user.idUsuario === idUsuario)?.nombre ?? '';
    setInfoDialogState({
      trackId,
      track,
      driver,
      unit: `${unidadesArray.find((u) => `${u.imei}` === `${imei}`)?.unidad ?? ''}`,
      createdAt,
      initDate: trackInitFmtDate.toLocaleDateString('es-MX', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      initTime: localeTimeString(trackInitFmtDate),
      end: (fin) ? localeTimeString(new Date(fin)) : '',
      mainDiffDisplay,
      stateRow,
      checkerName,
      arrayTable,
      comentario: comentario ?? '',
    });
    handleInfoDialog(true);
  };

  const handleCopy = async (routesIds) => {
    const unidades = currentTracks
      .filter((m) => routesIds.includes(m.idControlRuta) && !m.terminado && !m.cancelado)
      .map((m) => unidadesArray.find((u) => u.idUnidad === m.idUnidad).unidad)
      .join(', ');
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(unidades);
      } catch (err) {
        console.error('Error al copiar!', err);
        setMsg('Error al copiar.');
      }
    } else {
      console.error('No se tiene acceso al clipboard del navegador.');
    }
  };

  return (
    <Container
      css={initBox}
      maxWidth={false}
      disableGutters
    >
      <InfoDialog
        infoDialogState={infoDialogState}
        handleClose={() => handleInfoDialog(false)}
        title="Detalle del recorrido"
        setCurrentTracks={setCurrentTracks}
        token={token}
      />
      <ActionDialog
        modalIdTitle="actions-track"
        infoPanel={infoPanel}
        isModalOpen={isDialogOpen}
        isButtonCancel={false}
        dialogType={dialogType}
        liftingActionConfirm={liftingActionConfirm}
        handleClose={() => setIsDialogOpen(false)}
      />
      <AppBar
        visibleStateRows={visibleStateRows}
        dateFilter={false}
        filterRoutes={filtroRutas}
        rutas={rutas}
        newTracksList={undefined}
        searchBarTextDisplay={searchBarTextDisplay}
        secondary={secondary}
        handleSearch={debouncedFilterString}
        handleLogOut={() => setIsLogged(false)}
        handleStateRow={false}
        handleFilterRoutes={(filter) => setFiltroRutas(filter)}
        handleDateFilter={false}
        handleUpdateTracks={undefined}
        recoversCountMessage={undefined}
        isFilterStringToday={undefined}
        handleCopy={handleCopy}
        location="En curso"
      />
      <Alert open={open} setOpen={setOpen} msg={msg} alertType={alertType} />
      <MainTable
        mainDataArray={mainDataArray}
        checadas={checadas}
        unidades={unidadesArray}
        secondary={(secondary === 'true')}
        pointsRoute={pointsRoute}
        visibleStateRows={visibleStateRows}
        page={page}
        rowsPerPage={rowsPerPage}
        liftingAction={liftingAction}
        setInfoInDialog={handlePanelInfoDialog}
        handlePageChange={(event, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleComputePoints={undefined}
        isNewUptList={undefined}
        recoversList={undefined}
        isLoadingTable={undefined}
        updatesTracksArray={undefined}
        computedTracks={undefined}
        typeTable="mainScreen"
      />
    </Container>
  );
};

export default MainScreen;
