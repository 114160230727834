import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import COLORS from '../styles/Colors';

const StyledButton = styled(Button)(() => ({
  borderColor: `${COLORS.GREEN400}`,
  borderWidth: '2px',
  color: `${COLORS.GREEN400}`,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: `${COLORS.NEUTRAL700}`,
    borderColor: `${COLORS.GREEN200}`,
    color: `${COLORS.GREEN200}`,
    borderWidth: '2px',
  },
}));

const DarkButton = (props) => {
  const { icon, onClick, children } = props;

  return (
    <StyledButton
      variant="outlined"
      endIcon={icon}
      fullWidth
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default DarkButton;
