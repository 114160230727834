/* eslint-disable max-len */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import SECRETS from './secrets';
import AppBar from './Components/AppBar';
import COLORS from './Components/styles/Colors';
import '@fontsource/roboto-mono/500.css';

const pageStyle = {
  height: '100vh',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr',
};

const StyledTable = styled(Table)(() => ({
  borderRadius: 0,
  '& .MuiTableCell-head': {
    color: COLORS.SLATE50,
    backgroundColor: COLORS.SLATE700,
    borderRadius: 0,
    padding: '0.25rem 0.875rem',
  },
  '& .MuiTableCell-body': { padding: '0.25rem 0.875rem' },
}));

const StyledBoxLoader = styled(Box)(() => ({
  backgroundColor: 'white',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flex: '1 1 auto',
  height: '100%',
  width: '100%',
  zIndex: 50,
}));

const StyledTypoLoader = styled(Typography)(() => ({
  fontFamily: '"Roboto Mono", monospace',
  marginTop: '0.875rem',
  fontSize: 20,
  color: `${COLORS.SLATE500}`,
}));

const twoDigits = (num) => {
  const numTwoDigits = (num <= 9) ? `0${num}` : `${num}`;
  return numTwoDigits;
};

const LastCheckpointScreen = ({
  token,
  logOut,
  checkSession,
  secondary,
}) => {
  const [isLogged, setIsLogged] = useState(true);
  const [dateFilter, setDateFilter] = useState('');
  const [recorridos, setRecorridos] = useState(undefined);
  const [rutas, setRutas] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filtroRutas, setFiltroRutas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => { if (!checkSession()) setIsLogged(false); });

  useEffect(() => {
    if (secondary === 'false') return;
    navigate('/');
  }, [secondary]);

  useEffect(() => {
    const date = new Date();
    setDateFilter(`${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`);
  }, []);

  useEffect(() => {
    if (!isLogged) {
      logOut();
      navigate('/');
    }
  }, [isLogged]);

  useEffect(() => {
    setRecorridos(undefined);
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    if (dateFilter !== '') {
      axios
        .get('/unidades/organizacion')
        .then((response) => {
          const unitsArray = response.data;
          const unitsSorted = unitsArray.sort((a, b) => a.unidad.localeCompare(b.unidad));
          axios.defaults.headers.common.Authorization = `bearer ${token}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .post('/controlrecorrido', { fecha: dateFilter })
            .then((resp) => {
              const allRecorridos = resp.data.filter((r) => r.puntos.length > 0).map((r) => {
                const lastPunto = r.puntos.sort((a, b) => b.id - a.id)[0];
                return {
                  ...r,
                  unidad: unitsSorted.find((u) => u.idUnidad === r.idUnidad),
                  lastPunto,
                };
              });
              setRecorridos(allRecorridos.filter((obj) => {
                const time = new Date(obj.lastPunto.fecha);
                const olderRecorrido = allRecorridos
                  .find((tmp) => obj.idControlOperador === tmp.idControlOperador && new Date(tmp.lastPunto.fecha) > time);
                return typeof olderRecorrido === 'undefined';
              }));
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => console.error(e));
    }
  }, [dateFilter]);

  useEffect(() => {
    async function mainLoad() {
      let loginError = false;
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      const response = await axios.get('/controlruta').catch((error) => {
        if (error.response.status === 401) loginError = true;
      });
      if (!loginError) {
        setRutas(response.data);
      } else {
        setIsLogged(false);
      }
    }
    mainLoad();
  }, []);

  const filterWithSearch = (row) => {
    const removeTilde = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const matcher = new RegExp(`.*${removeTilde(searchValue.toLowerCase())}.*`);
    return (matcher.test(removeTilde(row.nombreRuta.toLowerCase()))
    || matcher.test(removeTilde(row.unidad.unidad.toLowerCase()))
    || matcher.test(removeTilde(row.nombreOperador.toLowerCase())));
  };

  const liftingSearch = (searchValueString) => setSearchValue(searchValueString);

  return (
    <div style={pageStyle}>
      <AppBar
        visibleStateRows={[]}
        dateFilter={dateFilter}
        filterRoutes={filtroRutas}
        rutas={rutas}
        newTracksList={undefined}
        searchBarTextDisplay={searchValue}
        isVisibleNewTracksLength={false}
        secondary={secondary}
        handleSearch={liftingSearch}
        handleLogOut={() => setIsLogged(false)}
        handleStateRow={false}
        handleFilterRoutes={setFiltroRutas}
        handleDateFilter={(dateFilterValue) => setDateFilter(dateFilterValue)}
        handleUptTracks={undefined}
        location="Reporte"
      />
      <Paper sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
        {recorridos === undefined && (
          <StyledBoxLoader>
            <div className="loader-spinner" />
            <StyledTypoLoader>
              Cargando...
            </StyledTypoLoader>
          </StyledBoxLoader>
        )}
        {recorridos !== undefined && (
          <TableContainer className="last-checkpoint-table">
            <StyledTable stickyHeader aria-label="collapsible table">
              <TableHead style={{ borderRadius: 0 }}>
                <TableRow>
                  <TableCell
                    align="center"
                    variant="head"
                    padding="none"
                    colSpan="1"
                  >
                    Recorrido
                  </TableCell>
                  <TableCell
                    align="center"
                    variant="head"
                    padding="none"
                    colSpan="1"
                  >
                    Unidad
                  </TableCell>
                  <TableCell
                    align="center"
                    variant="head"
                    padding="none"
                    colSpan="1"
                  >
                    Operador
                  </TableCell>
                  <TableCell
                    align="center"
                    variant="head"
                    padding="none"
                    colSpan="1"
                  >
                    Último
                  </TableCell>
                  <TableCell
                    align="center"
                    variant="head"
                    padding="none"
                    colSpan="1"
                  >
                    Hora
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ position: 'relative', height: '100%' }}>
                {recorridos && recorridos
                  .filter(filterWithSearch)
                  .filter((rec) => filtroRutas.length === 0 || filtroRutas.includes(rec.idControlRuta))
                  .sort((a, b) => new Date(b.lastPunto.fecha) - new Date(a.lastPunto.fecha))
                  .map((recorrido) => (
                    <TableRow key={`recorrido-${recorrido.id}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
                      <TableCell variant="body" padding="none" component="td" scope="row" align="center">{recorrido.nombreRuta}</TableCell>
                      <TableCell variant="body" padding="none" component="td" scope="row" align="center">{recorrido.unidad.unidad}</TableCell>
                      <TableCell variant="body" padding="none" component="td" scope="row" align="center">{recorrido.nombreOperador}</TableCell>
                      <TableCell variant="body" padding="none" component="td" scope="row" align="center">{recorrido.lastPunto?.nombrePunto}</TableCell>
                      <TableCell variant="body" padding="none" component="td" scope="row" align="center">{(new Date(recorrido.lastPunto?.fecha)).toTimeString().split(' ')[0]}</TableCell>
                    </TableRow>
                  ))}
                {recorridos && recorridos.length === 0 && (
                  <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">
                      No hay registros de este día.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
        )}
      </Paper>
    </div>
  );
};

export default LastCheckpointScreen;
