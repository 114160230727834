import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Container } from '@mui/system';
import {
  Button, TextField, Table, TableHead, TableBody, TableRow, TableCell, Checkbox, ButtonGroup,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import SECRETS from './secrets';
import Alert from './Components/Alert';
import COLORS from './Components/styles/Colors';
import TopBar from './Components/TopBar';

const flex = {
  display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: '8px', padding: '16px', boxSizing: 'border-box',
};

const styles = {
  buttonInfo: {
    color: COLORS.SKY700,
    backgroundColor: COLORS.SKY100,
    '&:hover': {
      color: COLORS.SKY800,
      backgroundColor: COLORS.SKY200,
    },
  },
  buttonDanger: {
    color: `${COLORS.RED700}`,
    backgroundColor: `${COLORS.RED100}`,
    borderColor: `${COLORS.RED400}`,
    '&:hover': {
      color: `${COLORS.RED800}`,
      backgroundColor: `${COLORS.RED200}`,
      borderColor: `${COLORS.RED500}`,
    },
  },
  buttonNormal: {
    borderColor: COLORS.GREEN400,
    borderWidth: '2px',
    color: COLORS.GREEN400,
    transition: 'all 0.25s ease-out',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: COLORS.GREEN300,
      borderColor: COLORS.GREEN300,
      color: 'white',
      transition: 'all 0.25s ease-in',
      borderWidth: '2px',
    },
  },
  table: {
    width: '100%',
    display: 'block',
  },
  tableRow: {
    borderBottom: '1px solid #e0e0e0',
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr max-content',
    alignItems: 'center',
  },
  tableCell: {
    boxSizing: 'border-box',
    borderBottom: 'none',
  },
  tableCellCustom: {
    width: '100%',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'none',
    boxSizing: 'border-box',
  },
  checkbox: {
    color: COLORS.NEUTRAL300,
    '&.Mui-checked': {
      color: COLORS.GREEN400,
    },
  },
};

const buildRequestOptions = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
  baseURL: SECRETS.SERVERURL,
});

const getOperators = async (token, setIsLogged) => {
  try {
    let loginError = false;
    const response = await axios.get('/controloperador', buildRequestOptions(token)).catch((error) => {
      if (error.response.status === 401) loginError = true;
    });
    if (!loginError) {
      return { operators: response.data };
    }
    setIsLogged(false);
    return false;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde';

    throw new Error(errorMessage);
  }
};

const postOperator = async (token, operator) => {
  try {
    const response = await axios.post('/controloperador', { nombre: operator }, buildRequestOptions(token));

    return { operator: response.data.operador };
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde';

    throw new Error(errorMessage);
  }
};

const patchOperator = async (token, operator) => {
  try {
    const response = await axios.patch(`/controloperador/${operator.id}`, operator, buildRequestOptions(token));

    return { operator: response.data.operador };
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde';

    throw new Error(errorMessage);
  }
};

const deleteOperator = async (token, operatorId) => {
  try {
    const response = await axios.delete(`/controloperador/${operatorId}`, buildRequestOptions(token));

    return { operator: response.data.operador };
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde';

    throw new Error(errorMessage);
  }
};

const validateName = (nombre) => /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]{3,50}$/.test(nombre);

const normalizedText = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim();

const compareObjects = (obj1, obj2) => {
  const a = JSON.stringify(obj1);
  const b = JSON.stringify(obj2);

  return a === b;
};

const findOperatorChanges = (olds, currents) => {
  const oldIndexed = olds.reduce((acc, operator) => ({ ...acc, [operator.id]: operator }), {});

  const changes = currents.reduce((acc, operator) => (
    compareObjects(operator, oldIndexed[operator.id]) ? acc : [...acc, operator]
  ), []);

  return changes;
};

const AddOperator = ({
  operators, setOperators, token, createAlert,
}) => {
  const [name, setName] = useState('');
  const [operatorState, setOperatorState] = useState({ error: false, message: '' });

  const handleSubmit = () => {
    if (!validateName(name)) {
      setOperatorState({
        error: true,
        message: 'El nombre debe tener entre 3 y 50 caracteres y no debe contener números',
      });
      return;
    }

    if (operators.some((op) => normalizedText(op.nombre) === normalizedText(name))) {
      setOperatorState({
        error: true,
        message: 'El nombre del conductor ya existe',
      });
      return;
    }

    postOperator(token, name.trim())
      .then(({ operator }) => {
        setOperators(
          [...operators, operator]
            .sort((a, b) => a.nombre.localeCompare(b.nombre)),
        );

        createAlert({
          type: 'success',
          message: 'Conductor agregado correctamente',
        });

        setName('');
        setOperatorState({ error: false, message: '' });
      }).catch((error) => {
        setOperatorState({ error: true, message: error.message });
      });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ ...flex, padding: 0 }}>
      <TextField
        id="operator"
        type="text"
        label="Nombre Conductor"
        variant="outlined"
        value={name}
        error={operatorState.error}
        helperText={operatorState.message}
        onChange={({ target }) => setName(target.value)}
      />
      <Button type="submit" variant="outlined" sx={styles.buttonNormal}>Agregar</Button>
    </Box>
  );
};

const MemoizedTableRow = React.memo(({ operator }) => (
  <TableRow key={operator.id} sx={styles.tableRow}>
    <TableCell padding="checkbox" sx={styles.tableCell}>
      <Checkbox
        inputProps={{
          'data-id': operator.id,
          'data-action': 'select',
        }}
        checked={operator.activo}
        color="primary"
        sx={styles.checkbox}
      />
    </TableCell>
    <TableCell sx={styles.tableCellCustom}>
      {operator.nombre}
      <ButtonGroup size="small">
        <Button
          data-id={operator.id}
          data-action="edit"
          variant="outlined"
          sx={styles.buttonInfo}
          fontSize="small"
          startIcon={<EditIcon fontSize="small" />}
        >
          Editar
        </Button>
        <Button
          data-id={operator.id}
          data-action="remove"
          variant="outlined"
          fontSize="small"
          sx={styles.buttonDanger}
          startIcon={<DeleteIcon fontSize="small" />}
        >
          Eliminar
        </Button>
      </ButtonGroup>
    </TableCell>
  </TableRow>
));

const EditOperator = ({
  operator, setOperators, operators, createAlert, token, setToEdit,
}) => {
  const [name, setName] = useState(operator.nombre ?? '');
  const [state, setOperatorState] = useState({ error: false, message: '' });

  const handleSubmit = () => {
    if (!validateName(name)) {
      setOperatorState({
        error: true,
        message: 'El nombre debe tener entre 3 y 50 caracteres y no debe contener números',
      });
      return;
    }

    if (operators.some((op) => {
      const hasName = normalizedText(op.nombre) === normalizedText(name);
      const isSameId = op.id === operator.id;

      return hasName && !isSameId;
    })) {
      setOperatorState({
        error: true,
        message: 'El nombre del conductor ya existe',
      });
      return;
    }

    const handleSuccess = ({ operator: updated }) => {
      const updateState = (prevState) => prevState.map((element) => {
        if (element.id !== updated.id) return element;

        return { ...updated };
      })
        .sort((a, b) => a.nombre.localeCompare(b.nombre));

      setOperators(updateState);

      createAlert({
        type: 'success',
        message: 'Conductor actualizado correctamente',
      });
    };

    const handleError = (error) => {
      createAlert({
        type: 'error',
        message: error.message ?? 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde',
      });
    };

    patchOperator(token, { ...operator, nombre: name.trim() })
      .then(handleSuccess)
      .catch(handleError);
  };

  return (
    <TableRow sx={styles.tableRow}>
      <TableCell padding="checkbox" sx={{ ...styles.tableCell, textAlign: 'center' }}>
        <Checkbox
          inputProps={{
            'data-id': operator.id,
            'data-action': 'select',
          }}
          checked={operator.activo}
          color="primary"
          sx={styles.checkbox}
        />
      </TableCell>
      <TableCell style={{
        ...styles.tableCellCustom,
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      >
        <TextField
          id="operator"
          type="text"
          label="Nombre Conductor"
          variant="outlined"
          value={name}
          error={state.error}
          helperText={state.message}
          onChange={({ target }) => setName(target.value)}
        />
        <ButtonGroup size="small">
          <Button
            size="small"
            onClick={handleSubmit}
            variant="outlined"
            sx={{ ...styles.buttonInfo, width: '100%' }}
            fontSize="small"
            startIcon={<CheckIcon fontSize="small" />}
          >
            Guardar
          </Button>
          <Button
            onClick={() => setToEdit(null)}
            variant="outlined"
            fontSize="small"
            sx={{ ...styles.buttonDanger, width: '100%' }}
            startIcon={<CloseIcon fontSize="small" />}
          >
            Cancelar
          </Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

const RemoveOperator = ({
  operator, setOperators, createAlert, token, setToRemove,
}) => {
  const handleConfirm = () => {
    deleteOperator(token, operator.id).then(() => {
      setOperators((prevCache) => prevCache
        .filter((prevOperator) => prevOperator.id !== operator.id));
      createAlert({ type: 'success', message: 'Operador eliminado correctamente' });
    }).catch((error) => {
      createAlert({ type: 'error', message: error.message });
    });
  };

  return (
    <TableRow sx={styles.tableRow}>
      <TableCell padding="checkbox" sx={styles.tableCell}>
        <Checkbox
          inputProps={{
            'data-id': operator.id,
            'data-action': 'select',
          }}
          checked={operator.activo}
          color="primary"
          sx={styles.checkbox}
        />
      </TableCell>
      <TableCell sx={styles.tableCellCustom}>
        {operator.nombre}
        <ButtonGroup size="small">
          <Button
            onClick={handleConfirm}
            variant="outlined"
            sx={{ ...styles.buttonInfo, width: '100%' }}
            fontSize="small"
            startIcon={<CheckIcon fontSize="small" />}
          >
            Confirmar
          </Button>
          <Button
            onClick={() => setToRemove(null)}
            variant="outlined"
            fontSize="small"
            sx={{ ...styles.buttonDanger, width: '100%' }}
            startIcon={<CloseIcon fontSize="small" />}
          >
            Cancelar
          </Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

const TableOperators = ({
  token,
  operators,
  setOperators,
  createAlert,
}) => {
  const [cache, setCache] = useState(operators);
  const [allSelected, setAllSelected] = useState(false);
  const [toEdit, setToEdit] = useState(null);
  const [toRemove, setToRemove] = useState(null);

  const setAllSelectedToActive = () => setAllSelected(
    cache.every((operator) => operator.activo === true),
  );

  const handleAction = (event) => {
    const { target } = event;
    const element = target.tagName === 'INPUT' ? target : target.closest('button');

    if (!element) return;

    const { id, action } = element.dataset;

    if (!id || !action) return;

    const operatorId = Number(id);

    const applyAction = {
      select: () => {
        setCache((prevCache) => prevCache.map((prevOperator) => (prevOperator.id === operatorId
          ? { ...prevOperator, activo: !prevOperator.activo }
          : prevOperator)));
      },
      edit: () => {
        setToEdit(operatorId);
        setToRemove(null);
      },
      remove: () => {
        setToRemove(operatorId);
        setToEdit(null);
      },
    }[action];

    if (applyAction) applyAction();
  };

  const handleOperatorSelectAll = () => {
    const newAllSelected = !allSelected;
    setAllSelected(newAllSelected);

    setCache((prevCache) => prevCache.map((operator) => ({ ...operator, activo: newAllSelected })));
  };

  const onSave = () => {
    const changes = findOperatorChanges(operators, cache);

    const patches = changes.map(async (change) => patchOperator(token, change));

    Promise.all(patches)
      .then(() => {
        createAlert({
          message: 'Operadores actualizados correctamente.',
          type: 'success',
        });

        setOperators(cache);
      })
      .catch(() => {
        createAlert({
          message: 'Por favor recarga la página, estas viendo valores no actualizados.',
          type: 'error',
        });

        setOperators(operators);
      });
  };

  useEffect(setAllSelectedToActive, [cache]);
  useEffect(() => {
    setCache(operators);
    setToEdit(null);
  }, [operators]);

  return (
    <Box sx={{ ...flex, padding: 0 }}>
      <Table sx={{
        ...styles.table, maxHeight: '55vh', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: 'max-content 1fr',
      }}
      >
        <TableHead sx={{ ...styles.table, background: '#eeeeee' }}>
          <TableRow style={styles.tableRow}>
            <TableCell padding="checkbox" style={styles.tableCell}>
              <Checkbox
                edge="end"
                color="default"
                checked={allSelected}
                onChange={handleOperatorSelectAll}
                sx={styles.checkbox}
              />
            </TableCell>
            <TableCell sx={styles.tableCellCustom}>
              Operador
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{ ...styles.table, height: '100%', overflow: 'auto' }}
          onClick={handleAction}
        >
          {cache.map((operator) => {
            if (toRemove === operator.id) {
              return (
                <RemoveOperator
                  createAlert={createAlert}
                  operator={operator}
                  setOperators={setOperators}
                  setToRemove={setToRemove}
                  token={token}
                  key={operator.id}
                />
              );
            }
            if (operator.id === toEdit) {
              return (
                <EditOperator
                  key={operator.id}
                  operator={operator}
                  setOperators={setOperators}
                  operators={operators}
                  createAlert={createAlert}
                  token={token}
                  setToEdit={setToEdit}
                />
              );
            }

            return <MemoizedTableRow key={operator.id} operator={operator} />;
          })}
        </TableBody>
      </Table>
      <Button type="submit" variant="outlined" onClick={onSave} sx={styles.buttonNormal}>Guardar</Button>
    </Box>
  );
};

const OperatorsScreen = ({
  token,
  logOut,
  secondary,
  checkSession,
}) => {
  const [isLogged, setIsLogged] = useState(true);
  const [operators, setOperators] = useState([]);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [alertType, setAlertType] = useState('');

  const navigate = useNavigate();

  const createAlert = ({ message, type }) => {
    setMsg(message);
    setAlertType(type);
    setOpen(true);
  };

  useEffect(() => { if (!checkSession()) setIsLogged(false); });

  useEffect(() => {
    if (secondary === 'false') return;
    navigate('/');
  }, [secondary]);

  useEffect(() => {
    if (!isLogged) {
      logOut();
      navigate('/');
    }
  }, [isLogged]);

  useEffect(() => {
    async function mainLoad() {
      const res = await getOperators(token, setIsLogged);
      setOperators(res.operators);
    }
    mainLoad();
  }, []);

  return (
    <>
      <Alert
        {...{
          msg,
          alertType,
          open,
          setOpen,
        }}
      />
      <TopBar
        secondary={secondary}
        tools={undefined}
        handleLogOut={logOut}
        location="Operators"
      >
        <Container
          sx={{ flex, padding: '16px 0 0 0' }}
          maxWidth="sm"
        >
          <AddOperator {...{
            token, operators, setOperators, createAlert,
          }}
          />
          <TableOperators {...{
            token, operators, setOperators, createAlert,
          }}
          />
        </Container>
      </TopBar>
    </>
  );
};

export default OperatorsScreen;
