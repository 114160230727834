import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import COLORS from '../styles/Colors';

const DarkInput = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: COLORS.GREEN500,
  },
  '& label': {
    color: COLORS.GREEN500,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLORS.GREEN500,
  },
  '& .MuiFormHelperText-root': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.GREEN500,
    },
    '&:hover fieldset': {
      borderColor: COLORS.GREEN500,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.GREEN500,
    },
  },
}));

export default DarkInput;
