import React, { useState, useEffect } from 'react';
import {
  Routes, Route, HashRouter,
} from 'react-router-dom';

import { io } from 'socket.io-client';
import axios from 'axios';
import SECRETS from './secrets';

import Login from './Login';
import MainScreen from './MainScreen';
import HistoryScreen from './HistoryScreen';
import LastCheckpointScreen from './LastCheckpointScreen';
import OperatorsScreen from './OperatorsScreen';
import DelayScreen from './DelayScreen';
import TimesScreen from './TimesScreen';
import FrequencyScreen from './FrequencyScreen';

const socket = io(SECRETS.SOCKETURL, {
  reconnectionDelay: 10000, // defaults to 1000
  reconnectionDelayMax: 10000, // defaults to 5000
});

const useStorageState = (key, initialState) => {
  const [value, setValue] = useState(
    localStorage.getItem(key) || initialState,
  );
  useEffect(() => {
    if (value !== undefined) localStorage.setItem(key, value);
    else localStorage.removeItem(key);
  }, [value, key]);
  return [value, setValue];
};

const App = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [id, setId] = useStorageState('id', 0);
  const [token, setToken] = useStorageState('token', '');
  const [permissionLevel, setPermissionLevel] = useStorageState('permissionLevel', 1);
  const [secondary, setSecondary] = useStorageState('secondary', null);

  const logOut = () => {
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.post('/logout', { token })
      .catch((reason) => {
        console.error(`logOut method App error:: ${reason}`);
      });
    setId(undefined);
    setToken(undefined);
    setPermissionLevel(undefined);
    setSecondary(undefined);
    setIsLogged(false);
  };

  const checkSession = () => {
    const intId = parseInt(id, 10);
    const intPermission = parseInt(permissionLevel, 10);
    if (
      typeof intId === 'number'
      && !Number.isNaN(intId)
      && intId > 0
      && !Number.isNaN(intPermission)
      && typeof intPermission === 'number'
      && intPermission > 0
      && typeof token === 'string'
      && token.length > 0
    ) {
      return true;
    }
    return false;
  };

  const checkSessionStorage = () => {
    setId(localStorage.getItem('id'));
    setToken(localStorage.getItem('token'));
    setPermissionLevel(localStorage.getItem('permissionLevel'));
    setSecondary(localStorage.getItem('secondary'));
    const isSession = checkSession();
    if (!isSession) logOut();
  };

  useEffect(() => {
    window.addEventListener('storage', checkSessionStorage);

    if (secondary !== null) return;
    logOut();

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('storage', checkSessionStorage);
    };
  }, []);

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={(
            <Login
              isLogged={isLogged}
              setIsLogged={setIsLogged}
              setId={setId}
              setToken={setToken}
              id={id}
              token={token}
              setPermissionLevel={setPermissionLevel}
              setSecondary={setSecondary}
            />
          )}
        />
        <Route
          path="/main"
          element={(
            <MainScreen
              socket={socket}
              token={token}
              logOut={logOut}
              checkSession={checkSession}
              secondary={secondary}
            />
          )}
        />
        <Route
          path="/history"
          element={(
            <HistoryScreen
              socket={socket}
              token={token}
              logOut={logOut}
              checkSession={checkSession}
              secondary={secondary}
            />
          )}
        />
        <Route
          path="/lastcheckpoint"
          element={(
            <LastCheckpointScreen
              token={token}
              logOut={logOut}
              checkSession={checkSession}
              secondary={secondary}
            />
          )}
        />
        <Route
          path="/operators"
          element={(
            <OperatorsScreen
              token={token}
              logOut={logOut}
              checkSession={checkSession}
              secondary={secondary}
            />
          )}
        />
        <Route
          path="/delays"
          element={(
            <DelayScreen
              token={token}
              logOut={logOut}
              checkSession={checkSession}
              secondary={secondary}
            />
          )}
        />
        <Route
          path="/times"
          element={(
            <TimesScreen
              token={token}
              logOut={logOut}
              checkSession={checkSession}
              secondary={secondary}
            />
          )}
        />
        <Route
          path="/frequencies"
          element={(
            <FrequencyScreen
              token={token}
              logOut={logOut}
              checkSession={checkSession}
              secondary={secondary}
            />
          )}
        />
      </Routes>
    </HashRouter>
  );
};

export default App;
