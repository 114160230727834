import React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import COLORS from '../styles/Colors';

const StyledIconButton = styled(MuiIconButton)(({ disabled }) => ({
  borderColor: `${COLORS.GREEN400}`,
  color: `${COLORS.GREEN400}`,
  transition: 'all 0.25s ease-out',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: `${COLORS.NEUTRAL700}`,
    borderColor: `${COLORS.GREEN300}`,
    color: `${COLORS.GREEN300}`,
    transition: 'all 0.25s ease-in',
  },
  ...(disabled && {
    borderColor: `${COLORS.GREEN100}`,
    color: `${COLORS.NEUTRAL500}`,
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: `${COLORS.GREEN100}`,
      color: `${COLORS.GREEN100}`,
    },
  }),
}));

const DarkIconButton = (props) => {
  const { icon, onClick, disabled } = props;

  return (
    <StyledIconButton onClick={onClick} disabled={disabled}>
      {icon}
    </StyledIconButton>
  );
};

export default DarkIconButton;
